import { createAction } from 'redux-act';
//////////////////////////////////////////////////

export const setChartsToStore = createAction('setChartsToStore');
export const setFleetsToStore = createAction('setFleetsToStore');
export const setIdleTimeFilter = createAction('setIdleTimeFilter');
export const cleanFleetMapStore = createAction('cleanFleetMapStore');
export const setEnterpriseFilter = createAction('setEnterpriseFilter');

export const setFleetsLoadedToStore = createAction('setFleetsLoadedToStore');
export const openMapPageFromFleetList = createAction('openMapPageFromFleetList');
export const setGeoFencingLocationToStore = createAction('setGeoFencingLocationToStore');
