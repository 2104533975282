import React from 'react';
import * as Yup from 'yup';
import * as R from 'ramda';
import { css } from 'styled-components';
import { FieldArray, withFormik } from 'formik';
import { pure, compose, withState } from 'react-recompose';
// components
import { Tabs2 } from '../../../../../components/tabs-mui';
import { FormFooter } from '../../../../../components/form-footer';
import { FormSectionHeader } from '../../../../../components/form-section-header';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// hocs
import { withAsyncGetBranchListByType } from '../../../../../hocs';
// icons
import * as I from '../../../../../svgs';
// ui
import { Box, Flex, scrollableContainerCss4px } from '../../../../../ui';
// feature config
import {
  ediExporterConfigFields,
  ediExporterDeclineReasonFields,
  defaultEdiExporterConfigFields,
  ediExporterConfigValidationSchema,
  defaultEdiExporterDeclineReasonFields,
} from '../settings';
//////////////////////////////////////////////////

const tabs = [
  { text: G.getWindowLocale('titles:general', 'General') },
  { text: `${G.getWindowLocale('titles:invoice', 'Invoice')} (210)` },
  { text: `${G.getWindowLocale('titles:status-message', 'Status Message')} (214)` },
  { text: `${G.getWindowLocale('titles:tender-response', 'Tender Response')} (990)` },
  { text: `${G.getWindowLocale('titles:functional-acknowledgment', 'Functional Acknowledgment')} (997)` },
];

const filedKeysMap = [
  [
    GC.FIELD_CUSTOMER_GUID,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_FOLDER_NAME,
    GC.FIELD_TRANSACTION_TYPES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PROTOCOL,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_HOST,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_REMOTE_PORT,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_USERNAME,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PASSWORD,
    GC.FIELD_AUTH_KEY,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_SENDER_ID,
    GC.FIELD_EDI_SENDER_QUALIFIER,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_PRIMARY_REFERENCE,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_RECEIVER_ID,
    GC.FIELD_RECEIVER_QUALIFIER,
    GC.FIELD_CONTENT_FORMAT,
    GC.FIELD_SCAC_OVERRIDE,
  ],
  [
    GC.FIELD_ENCODING_TYPE,
    GC.FIELD_INVOICE_RECEIVER_ID,
    GC.FIELD_INVOICE_RECEIVER_QUALIFIER,
    GC.FIELD_SEND_CARRIER_LOCATION,
    GC.FIELD_BILL_TO_LOCATION_IDENTIFIER,
    GC.FIELD_ORIGIN_LOCATION_IDENTIFIER,
    GC.FIELD_LOCATION_IDENTIFICATION_QUALIFIER,
    GC.FIELD_INVOICE_REFERENCES,
    GC.FIELD_INVOICE_ASSOCIATED_DOCUMENT_TYPES,
    GC.FIELD_INVOICE_ASSOCIATED_DOCUMENT_FOLDER_NAME,
  ],
  [
    GC.FIELD_STATUS_MESSAGE_EVENT_REFERENCES,
    GC.FIELD_STATUS_MESSAGE_ORDER_REFERENCES,
    GC.FIELD_SENT_STATUS_CODES,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_EVENT_WITH_STATUS,
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_SHIPMENT_WITH_STATUS,
    GC.FIELD_CUSTOM_MAPPER,
    GC.FIELD_STATUS_MESSAGE_DEFAULT_TIME_ZONE,
    GC.FIELD_STATUS_MESSAGE_RECEIVER_ID,
    GC.FIELD_STATUS_MESSAGE_RECEIVER_QUALIFIER,
    GC.FIELD_STATUS_MESSAGE_SEND_FIRST_LAST_EVENT,
    GC.FIELD_SEND_APPOINTMENT_DATES,
    GC.FIELD_STATUS_MESSAGE_SEND_NEXT_EVENT_INDEX,
    GC.FIELD_STATUS_MESSAGE_STOP_NUMBER_POSITION,
  ],
  [
    GC.FIELD_CONFIGURATION_INTEGRATION_EDI_EXTRACTOR_TENDER_RESPONSE_REFERENCE,
    GC.FIELD_ACCEPT_DECLINE_RECEIVER_ID,
    GC.FIELD_ACCEPT_DECLINE_RECEIVER_QUALIFIER,
    GC.FIELD_REFERENCES_TO_COMMENT,
    GC.FIELD_DECLINE_REASONS,
  ],
  [
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_ID,
    GC.FIELD_ACKNOWLEDGEMENT_RECEIVER_QUALIFIER,
  ],
];

const getFieldSettingsByActiveTab = (activeTab: number) => R.compose(
  R.values,
  R.pick(R.propOr([], activeTab, filedKeysMap)),
  R.indexBy(R.prop('fieldName')),
)(ediExporterConfigFields);

const enhance = compose(
  withState('activeTab', 'setActiveTab', 0),
  withState('declineReasonsExpanded', 'setDeclineReasonsExpanded', true),
  withAsyncGetBranchListByType(GC.BRANCH_TYPE_ENUM_CUSTOMER),
  withFormik({
    mapPropsToValues: (props: Object) => G.setInitialFormikValues(
      defaultEdiExporterConfigFields,
      props.initialValues,
    ),
    validationSchema: Yup.object().shape(ediExporterConfigValidationSchema),
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
  }),
  pure,
);

const DeclineReasons = (props: Object) => {
  const { form, push, remove, expanded, setExpanded } = props;

  const declineReasons = R.pathOr([], ['values', GC.FIELD_DECLINE_REASONS], form);

  return (
    <FormSectionHeader
      expanded={expanded}
      additionalStyles={{ mt: '0px', mb: 25 }}
      handleToggleSection={() => setExpanded(R.not)}
      action={() => push(defaultEdiExporterDeclineReasonFields())}
      title={G.getWindowLocale('titles:decline-reasons', 'Decline Reasons')}
    >
      {
        declineReasons.map((_: any, index: number) => (
          <Flex mt={25} key={index} mb={G.ifElse(R.equals(R.inc(index), R.length(declineReasons)), 25)}>
            {
              <Box mr={10} width={13} cursor='pointer' onClick={() => remove(index)}>
                {I.trash()}
              </Box>
            }
            <Fieldset2
              {...form}
              key={index}
              fields={ediExporterDeclineReasonFields(index)}
            />
          </Flex>
        ))
      }
    </FormSectionHeader>
  );
};

const EdiExporterConfigForm = (props: Object) => (
  <form
    id='edi_exporter_form'
    onSubmit={props.handleSubmit}
    css={css`
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 160px);

      ${scrollableContainerCss4px}
    `}
  >
    <Tabs2
      tabs={tabs}
      activeTab={props.activeTab}
      setActiveTab={props.setActiveTab}
      tabStyles={GC.COMMON_MUI_TAB_STYLES}
      tabsStyles={GC.COMMON_MUI_TABS_STYLES}
    />
    <Fieldset2
      {...G.getFormikProps(props)}
      {...R.assoc('branchListByType', props.branchListByType, props.optionsForSelect)}
      fields={getFieldSettingsByActiveTab(props.activeTab)}
      fieldsWrapperStyles={{ pt: 25, justifyContent: 'space-between' }}
    />
    {
      R.propEq(3, 'activeTab', props) &&
      <FieldArray
        name={GC.FIELD_DECLINE_REASONS}
        render={(arrayHelpers: Object) => (
          <DeclineReasons
            {...arrayHelpers}
            expanded={props.declineReasonsExpanded}
            setExpanded={props.setDeclineReasonsExpanded}
          />
        )}
      />
    }
    <FormFooter />
  </form>
);

export default enhance(EdiExporterConfigForm);
