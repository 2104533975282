import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// feature fleet-map
import * as Actions from './actions';
//////////////////////////////////////////////////

const initialState = {
  fleets: {
    trucks: [],
    drivers: [],
    trailers: [],
    fleetsLoaded: {
      trucks: false,
      drivers: false,
      trailers: false,
    },
    geoFencingLocation: null,
  },
  idleTimeFilter: [],
  enterpriseFilter: [],
};

const setFleetsToStore = (state: Object, { fleetType, data } : Object) => (
  P.$all(
    P.$set(`fleets.${fleetType}`, data),
    P.$set(`fleets.fleetsLoaded.${fleetType}`, true),
    state,
  )
);

const setGeoFencingLocationToStore = (state: Object, { results }: Object) =>
  P.$set('geoFencingLocation', results, state);

const setFleetsLoadedToStore = (state: Object, fleetsLoaded: Object) =>
  P.$set('fleets.fleetsLoaded', fleetsLoaded, state);

const setEnterpriseFilter = (state: Object, enterpriseFilter: any) =>
  P.$set('enterpriseFilter', enterpriseFilter, state);

const setIdleTimeFilter = (state: Object, idleTimeFilter: any) =>
  P.$set('idleTimeFilter', idleTimeFilter, state);

const cleanFleetMapStore = () => (
  initialState
);

export default createReducer({
  [Actions.setFleetsToStore]: setFleetsToStore,
  [Actions.setIdleTimeFilter]: setIdleTimeFilter,
  [Actions.cleanFleetMapStore]: cleanFleetMapStore,
  [Actions.setEnterpriseFilter]: setEnterpriseFilter,
  [Actions.setFleetsLoadedToStore]: setFleetsLoadedToStore,
  [Actions.setGeoFencingLocationToStore]: setGeoFencingLocationToStore,
}, initialState);
