import * as R from 'ramda';
import React, { useMemo, Fragment } from 'react';
// components
import { SelectMui } from '../../../components';
import { Switcher } from '../../../components/switcher';
import { TitlePanel } from '../../../components/title-panel';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// forms
import { Toggle } from '../../../forms';
// ui
import { Box, Flex, RelativeFlex, AbsoluteWrapper } from '../../../ui';
// feature fleet-map
import {
  getFleetsLength,
  idleTimeOptions,
  countFleetEntities,
  getMultiSwitchOptions,
  getDivisionBranchesOptions,
} from '../helpers';
//////////////////////////////////////////////////

const fleetMapTitles = {
  [GC.FLEET_TRUCK_REPORT]: G.getWindowLocale('titles:truck-map', 'Truck Map'),
  [GC.FLEET_TRAILER_REPORT]: G.getWindowLocale('titles:trailer-map', 'Trailer Map'),
};

const FiltersPanel = ({
  loaded,
  reportType,
  trucksToUse,
  driversToUse,
  trailersToUse,
  fromFleetList,
  trailersActive,
  withoutClustering,
  withoutGeoFencing,
  setActiveFleetType,
  handleSetIdleTimeFilter,
  handleSetEnterpriseFilter,
  handleSetWithoutClustering,
  handleSetWithoutGeoFencing,
}: Object) => {
  const fleets = useMemo(() => ({
    trucks: trucksToUse,
    drivers: driversToUse,
    trailers: trailersToUse,
  }), [trucksToUse, driversToUse, trailersToUse]);

  const count = useMemo(() =>
    R.propOr(getFleetsLength(fleets), reportType, {
      [GC.FLEET_TRUCK_REPORT]: countFleetEntities(trucksToUse),
      [GC.FLEET_TRAILER_REPORT]: countFleetEntities(trailersToUse),
    }), [fleets, reportType, trucksToUse, trailersToUse]);

  const mapTitle = useMemo(() =>
    R.propOr(G.getWindowLocale('titles:fleet-map', 'Fleet Map'), reportType, fleetMapTitles), [reportType]);

  return (
    <Flex>
      <TitlePanel
        display='flex'
        title={mapTitle}
        withCount={true}
        totalCount={count}
        withReload={false}
        usePortalForFilters={true}
        hiddenRightFilterInfo={true}
      />
      <RelativeFlex width='100%' height={62}>
        {
        loaded &&
        <AbsoluteWrapper top='24px' left='15px' zIndex='11'>
          <Flex ml={16}>
            { R.not(fromFleetList) &&
              <Switcher
                version={3}
                selectedOptionIndex={0}
                onSwitch={setActiveFleetType}
                options={getMultiSwitchOptions(fleets)}
              />
            }
            <Toggle
              ml={30}
              icons={false}
              checked={R.not(withoutClustering)}
              onChange={handleSetWithoutClustering}
            />
            <Box
              ml={10}
              color={G.getTheme('colors.greyMatterhorn')}
            >
              {G.getWindowLocale('titles:cluster-locations', 'Cluster Locations')}
            </Box>
            { trailersActive &&
              <Fragment>
                <Toggle
                  ml={30}
                  icons={false}
                  checked={R.not(withoutGeoFencing)}
                  onChange={handleSetWithoutGeoFencing}
                />
                <Box
                  ml={10}
                  color={G.getTheme('colors.greyMatterhorn')}
                >
                  {G.getWindowLocale('titles:geo-fencing-location', 'Geo Fencing Location')}
                </Box>
              </Fragment>
            }
            <Box
              ml={30}
              borderRadius={4}
              backgroundColor={G.getTheme('colors.white')}
            >
              <SelectMui
                multiple={true}
                wrapperStyles={{ width: 175 }}
                options={getDivisionBranchesOptions()}
                handleChangeCallback={handleSetEnterpriseFilter}
                label={G.getWindowLocale('titles:division-name', 'Division Name')}
              />
            </Box>
            { trailersActive &&
              <Box
                ml={30}
                borderRadius={4}
                backgroundColor={G.getTheme('colors.white')}
              >
                <SelectMui
                  multiple={false}
                  options={idleTimeOptions}
                  wrapperStyles={{ width: 175 }}
                  handleChangeCallback={handleSetIdleTimeFilter}
                  label={G.getWindowLocale('titles:idle-trailers-time', 'Idle Trailers Time')}
                />
              </Box>
            }
          </Flex>
        </AbsoluteWrapper>
      }
      </RelativeFlex>
    </Flex>
  );
};

export default FiltersPanel;
