import * as R from 'ramda';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature fleet-profile
import { makeSelectEntityBranchGuid, makeSelectPrimaryObjectGuid } from '../selectors';
//////////////////////////////////////////////////

const dailyLogAggregationFields = [
  {
    aggregation: 'SUM',
    name: GC.FIELD_STORED_TOTAL_DISTANCE,
  },
];

const map = {
  [GC.FLEET_DRIVER_DAILY_LOG_REPORT]: {
    primaryObjectKey: GC.FIELD_DRIVER_GUID,
    endpointName: 'driverDailyLogListSummary',
    aggregationFields: dailyLogAggregationFields,
  },
  [GC.FLEET_TRUCK_DAILY_LOG_REPORT]: {
    primaryObjectKey: GC.FIELD_TRUCK_GUID,
    endpointName: 'truckDailyLogListSummary',
    aggregationFields: dailyLogAggregationFields,
  },
};

const ReportSummary = ({ itemList, usedReport = {}, tableTitleFilters }: Object) => {
  const { type } = usedReport;

  const [summary, setSummary] = useState(null);

  const branchGuid = useSelector(makeSelectEntityBranchGuid());
  const primaryObjectGuid = useSelector(makeSelectPrimaryObjectGuid());

  const defaultUomSystem = G.getConfigGeneralUomCalcDefaultUomSystemFromWindow();

  const aggregationFieldsMap = {
    [GC.FIELD_STORED_TOTAL_DISTANCE]: {
      locale: ['titles:total-distance', 'Total Distance'],
      suffix: G.getUomLocale(R.pathOr(GC.UOM_MILE, [defaultUomSystem], GC.uomSystemToDistanceUomMap)),
    },
  };

  useEffect(() => {
    if (R.or(G.isNilOrEmpty(type), G.isNotNilAndNotEmpty(itemList))) return;

    const handleGetSummary = async () => {
      const searchCriteria = [
        ...G.ifElse(
          G.isNotNilAndNotEmpty(tableTitleFilters),
          R.values(tableTitleFilters),
          R.prop('searchCriteria', usedReport),
        ),
        {
          operation: 'equal',
          dataType: 'string',
          stringValue: primaryObjectGuid,
          propertyName: R.path([type, 'primaryObjectKey'], map),
        },
      ];

      const options = {
        data: {
          searchCriteria,
          [GC.CURRENT_BRANCH]: branchGuid,
          aggregationFields: R.path([type, 'aggregationFields'], map),
        },
      };

      const endpoint = endpointsMap[R.path([type, 'endpointName'], map)];

      const res = await sendRequest('post', endpoint, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        setSummary(data.items);
      } else {
        G.handleException('handleGetSummary exception');
      }
    };

    handleGetSummary();
  }, [itemList, usedReport, tableTitleFilters]);

  if (R.isNil(summary)) return null;

  const { value, fieldName } = R.or(R.head(summary), {});

  return (
    <Flex
      ml={20}
      mb='3px'
      px='8px'
      height={30}
      color='white'
      borderRadius='5px'
      bg='titleDarkBlue'
      justifyContent='center'
    >
      {G.getWindowLocale(...R.path([fieldName, 'locale'], aggregationFieldsMap))}: {G.mathRoundNumber(value)} {
        R.path([fieldName, 'suffix'], aggregationFieldsMap)
      }
    </Flex>
  );
};

export default ReportSummary;
