import * as R from 'ramda';
import * as P from 'plow-js';
import { createReducer } from 'redux-act';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
import { availableEdiExportMappers, availableEdiLoaderExportMappers } from '../../helpers/options';
// features
import { socketLBConfigStateReceived } from '../sockets-v2/actions';
// feature configs
import * as A from './actions';
import { getConfigsForCloConfigSuccess } from './config-pages/clo/actions';
import { getBranchConfigsForRatingSuccess } from './config-pages/rating/actions';
import { getGeneralConfigsForTelConfigSuccess } from './config-pages/tel/actions';
import { getConfigsForInvoiceConfigSuccess } from './config-pages/invoice/actions';
import {
  setRefTypesToConfig,
  setSequencesToConfig,
  getDeactivationRuleIndexFromStore,
  setInvoiceStatusToConfigStoreByInvoiceName,
  removeInvoiceStatusToConfigStoreByInvoiceName,
} from './helpers';
//////////////////////////////////////////////////

const defaultInvoiceScopes = {
  [GC.INVOICE_SCOPE_TYPE_DRIVER]: [],
  [GC.INVOICE_SCOPE_TYPE_VENDOR]: [],
  [GC.INVOICE_SCOPE_TYPE_CARRIER]: [],
  [GC.INVOICE_SCOPE_TYPE_CUSTOMER]: [],
  [GC.INVOICE_SCOPE_TYPE_SERVICE_VENDOR]: [],
};

const initialState = {
  currentGroupName: null,
  financial: {
    quickbooks: null,
  },
  [GC.CLO_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    guidsToItemsMap: {},
    termsAndConditionsDocument: {},
    defaultOrderAccessorialList: {},
    options: {
      sequences: [],
      orderType: [],
      accessorials: [],
      serviceTypes: [],
      equipmentType: [],
      orderAccessorials: [],
      cloReferenceTypes: [],
      telReferenceTypes: [],
      statusMessageList: [],
      transportationModes: [],
      accessorialConfigList: {},
      availableDocumentTypes: [],
    },
  },
  [GC.TEL_CONFIG_GROUP]: {
    initialValues: {},
    inheritedValues: {},
    guidsToItemsMap: {},
    defaultCarrierAccessorialList: {},
    options: {
      sequences: [],
      serviceTypes: [],
      cloReferenceTypes: [],
      telReferenceTypes: [],
      crossDockLocations: [],
      noFuelAccessorials: [],
      transportationModes: [],
      accessorialConfigList: {},
      availableDocumentTypes: [],
    },
  },
  [GC.GENERAL_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    guidsToItemsMap: {},
    accessorialsList: [],
    loadCustomStatusConfigList: {},
    transportationModeGroupingList: [],
    transportationModeConfigMappingList: {},
    options: {
      sequences: [],
      branchSequences: [],
      distanceCalculatorConfigOptions: [],
    },
  },
  [GC.OPTIONAL_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    guidsToItemsMap: {},
    options: {
      customerInvoiceReferenceTypes: [],
    },
  },
  [GC.PASSWORD_CONFIG_GROUP]: {
    initialValues: {},
    inheritedValues: {},
  },
  [GC.TEMPLATES_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    options: {
      sequences: [],
      availableLocationTypes: [],
    },
  },
  [GC.FLEET_GENERAL_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    options: {
      sequences: [],
    },
  },
  [GC.DRIVER_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    onboardingIntegrationList: [],
    options: {
      statusCodeList: [],
      statusMessageCodes: [],
      gpsStatusMessageCodes: [],
      trackingStatusMessageCodes: [],
    },
  },
  [GC.TRUCK_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
  },
  [GC.TRAILER_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
  },
  [GC.COMMUNICATION_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    statusMessages: [],
    dataSourceList: [],
    inheritedValues: {},
    documentsConfig: [],
    documentsFilter: '',
    notificationList: [],
    mailTemplateByKey: {},
    mailTemplateTypes: [],
    mailTemplateNames: [],
    notificationConfig: [],
    mailTemplateConfig: [],
    documentSendingList: [],
    integrationMailConfig: [],
    indexedDataSourceList: {},
    availableDocumentTypes: [],
    notificationTriggersFilter: '',
    notificationObjectTypeFilter: '',
    options: {
      cloReferenceTypes: [],
      telReferenceTypes: [],
      customerBranchList: [],
    },
  },
  [GC.INVOICE_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    guidsToItemsMap: {},
    expenseTypeList: {},
    glCodeMappingFilter: '',
    driverPayrollStatuses: [],
    customerIdMappingList: [],
    carrierInvoiceStatuses: [],
    qbAccountMappingFilter: '',
    customerInvoiceStatuses: [],
    invoiceStatusRuleFilter: '',
    departmentIdMappingList: [],
    sageAccountMappingFilter: '',
    fleetServiceInvoiceStatuses: [],
    glCodeMappingList: defaultInvoiceScopes,
    qbAccountMappingList: defaultInvoiceScopes,
    invoiceStatusRuleList: defaultInvoiceScopes,
    sageAccountMappingList: {
      [GC.INVOICE_SCOPE_TYPE_CARRIER]: [],
      [GC.INVOICE_SCOPE_TYPE_CUSTOMER]: [],
    },
    options: {
      sequences: [],
      orderType: [],
      templates: [],
      accessorialList: [],
      cloReferenceTypes: [],
      statusMessageCodes: [],
      customerBranchList: [],
      transportationModes: [],
      driverPayrollStatuses: [],
      availableDocumentTypes: [],
      carrierInvoiceStatuses: [],
      customerInvoiceStatuses: [],
      fuelRelatedAccessorialList: [],
      fleetServiceInvoiceStatuses: [],
      nonFuelRelatedAccessorialList: [],
      availableCarrierDocumentTypes: [],
    },
  },
  [GC.RATE_ENGINE_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    marginViolationRuleList: [],
    options: {
      transportationModes: [],
    },
  },
  [GC.SERVICE_VENDOR_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    options: {
      sequences: [],
    },
  },
  [GC.UI_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    cloDispatchBoardActionPanel: null,
    telDispatchBoardActionPanel: null,
    options: {
      truckTypes: [],
      cloReferenceTypes: [],
      telReferenceTypes: [],
    },
  },
  [GC.CARRIER_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    deactivationRuleList: [],
    onboardingPackageList: [],
    termsAndConditionsDocument: [],
    carrierScorecardConfigList: [],
    onboardingPackageDocumentList: {},
    options: {
      parentBranches: [],
      telReferenceTypes: [],
      transportationModes: [],
      availableDocumentTypes: [],
      dropCompletedStatusCodes: [],
      pickupCompletedStatusCodes: [],
    },
  },
  [GC.INTEGRATION_CONFIG_GROUP]: {
    dropdowns: {},
    fuelCardList: [],
    initialValues: {},
    ediConfigsList: [],
    tollChargeList: {},
    inheritedValues: {},
    publicCLOConfig: [],
    gpsIntegrationList: {},
    advancePaymentList: {},
    serviceMappingList: {},
    losPricingPlanList: {},
    loadBoardConfigList: [],
    serviceMappingFilter: '',
    accountingConfigList: {},
    truckTypeMappingList: {},
    losSubscriptionConfig: {},
    integrationConfigList: [],
    statusCodeMappingList: {},
    ediExporterConfigList: [],
    documentHubConfigList: {},
    serviceTypeMappingList: {},
    carrierSynchronization: [],
    ediExtractorConfigList: [],
    trailerTypeMappingList: {},
    documentTypeMappingList: {},
    dispatchIntegrationList: {},
    terminalIntegrationList: {},
    carrierPickupRequesterList: {},
    carrierEdiLoaderConfigList: {},
    carrierRateIntegrationList: {},
    equipmentMappingConfigList: {},
    statusReasonCodeMappingList: {},
    carrierEdiExporterConfigList: {},
    userMonitoringIntegrationList: {},
    statusCodeOutboundMappingList: {},
    factoringIntegrationConfigList: {},
    carrierIntegrationOverrideList: {},
    trailerTrackingIntegrationList: {},
    crossBorderIntegrationConfigList: {},
    statusReasonCodeOutboundMappingList: {},
    transportationModeConfigMappingList: [],
    options: {
      carriers: [],
      statusCodeList: [],
      carrierSequences: [],
      allStatusCodeList: [],
      cloReferenceTypes: [],
      telReferenceTypes: [],
      integrationConfigs: [],
      customerBranchList: [],
      [GC.TRUCK_TRUCK_TYPE]: [],
      [GC.GENERAL_SERVICES]: [],
      trackingStatusCodeList: [],
      [GC.GENERAL_EQUIPMENTS]: [],
      accessorialServiceCodes: [],
      [GC.TRAILER_TRAILER_TYPE]: [],
      availableEdiExportMappers: [],
      customerInvoiceReferenceTypes: [],
      [GC.COMMUNICATION_REASON_CODE]: [],
      [GC.COMMUNICATION_DOCUMENT_TYPE]: [],
      [GC.GENERAL_MODE_TRANSPORTATION]: [],
      allStatusCodeListWithStoredValues: [],
      availableEdiExportMappersForLoader: [],
      availableCarrierPickupRequesterList: [],
      [GC.GENERAL_TRANSPORTATION_SERVICE_TYPE]: [],
    },
  },
  [GC.MOBILEAPP_CONFIG_GROUP]: {
    warning: {},
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    options: {
      telReferenceTypes: [],
      availableDocumentTypes: [],
      dropCompletedStatusMessageCodes: [],
      pickupCompletedStatusMessageCodes: [],
      checkedInCompletedStatusMessageCodes: [],
    },
  },
  [GC.TASK_MANAGEMENT_CONFIG_GROUP]: {
    dropdowns: {},
    taskTypeList: {},
    initialValues: {},
    taskStatusList: {},
    inheritedValues: {},
    options: {
      sequences: [],
    },
  },

  [GC.CLAIM_CONFIG_GROUP]: {
    dropdowns: {},
    initialValues: {},
    inheritedValues: {},
    options: {
      sequences: [],
    },
  },
};

const setActiveConfigTab = (state: Object, tab: number) => {
  const { currentGroupName } = state;

  return P.$set(`${currentGroupName}.activeTab`, tab, state);
};

const setConfigTabsVisited = (state: Object, tabsVisited: Array) => {
  const { currentGroupName } = state;

  return P.$set(`${currentGroupName}.tabsVisited`, tabsVisited, state);
};

const getConfigByGroupSuccess = (state: Object, data: Object) => {
  const { groupName, res: { configs, dropdowns } } = data;
  let initialValues = {};
  let inheritedValues = {};
  R.forEach((item: Object) => {
    initialValues = R.assoc(item.name, item.value, initialValues);
    inheritedValues = R.assoc(item.name, item.inheritedValue, inheritedValues);
  }, configs);
  return P.$all(
    P.$set('currentGroupName', groupName),
    P.$set(`${groupName}.initialValues`, initialValues),
    P.$set(`${groupName}.inheritedValues`, inheritedValues),
    P.$set(`${groupName}.dropdowns`, R.indexBy(R.prop('name'), dropdowns)),
    state,
  );
};

const getReferenceTypesGuidsNamesMapSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$merge(`${GC.CLO_CONFIG_GROUP}.guidsToItemsMap`, data),
    P.$merge(`${GC.TEL_CONFIG_GROUP}.guidsToItemsMap`, data),
    P.$merge(`${GC.OPTIONAL_CONFIG_GROUP}.guidsToItemsMap`, data),
    state,
  )
);

const getSequencesGuidNameMapSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$merge(`${GC.CLO_CONFIG_GROUP}.guidsToItemsMap`, data),
    P.$merge(`${GC.TEL_CONFIG_GROUP}.guidsToItemsMap`, data),
    P.$merge(`${GC.GENERAL_CONFIG_GROUP}.guidsToItemsMap`, data),
    P.$merge(`${GC.INVOICE_CONFIG_GROUP}.guidsToItemsMap`, data),
    state,
  )
);

const getReferenceTypesByScopeForConfigSuccess = (state: Object, data: Object) => (
  setRefTypesToConfig(state, data)
);

const getSequencesByTypeForConfigSuccess = (state: Object, data: Object) => (
  setSequencesToConfig(state, data)
);

const getStatusMessagesForGeneralConfigSuccess = (state: Object, data: Object) => {
  const options = G.getOptionsFromConfigValueByParentGuidOrGuid(data);
  const tracking = G.getOptionsFromConfigValueByParentGuidOrGuid(R.filter(R.propEq(true, 'tracking'), data));

  const gps = G.getOptionsFromConfigValueByParentGuidOrGuid(R.filter((item: Object) => R.and(
      R.propEq(true, 'tracking', item),
      G.isNilOrEmpty(R.path(['statusType'], item)),
    ),
    data,
  ));

  return P.$all(
    P.$set(`${GC.DRIVER_CONFIG_GROUP}.options.gpsStatusMessageCodes`, gps),
    P.$set(`${GC.DRIVER_CONFIG_GROUP}.options.statusMessageCodes`, options),
    P.$set(`${GC.GENERAL_CONFIG_GROUP}.options.statusMessageCodes`, options),
    P.$set(`${GC.DRIVER_CONFIG_GROUP}.options.trackingStatusMessageCodes`, tracking),
    P.$set(`${GC.MOBILEAPP_CONFIG_GROUP}.options.trackingStatusMessageCodes`, tracking),
    state,
  );
};

const mapAccessorialsToOptions = (data: Array) => R.map(
  (item: Object) => ({ label: R.prop(GC.FIELD_DISPLAYED_VALUE, item), value: R.prop(GC.FIELD_GUID, item) }),
  data,
);

const getAccessorialForConfigSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$set(`${GC.GENERAL_CONFIG_GROUP}.accessorialsList`, data),
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.accessorialList`, data),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.fuelRelatedAccessorialList`,
      mapAccessorialsToOptions(R.filter(R.propEq(true, GC.FIELD_FUEL_RELATED), data)),
    ),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.nonFuelRelatedAccessorialList`,
      mapAccessorialsToOptions(R.filter(R.propEq(false, GC.FIELD_FUEL_RELATED), data)),
    ),
    state,
  )
);

const getBranchConfigsByNamesForCarrierGroupSuccess = (state: Object, data: Object) => {
  const mappedConfigs = G.mapConfigValuesByName(data);

  const transportationModeOptions = G.createOptionsFromDropdownConfigWithGuidOrParentGuid(
    mappedConfigs,
    GC.GENERAL_MODE_TRANSPORTATION,
  );

  return P.$set(`${GC.CARRIER_CONFIG_GROUP}.options.transportationModes`, transportationModeOptions, state);
};

const getCarrierDeactivationRuleListSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.CARRIER_CONFIG_GROUP}.deactivationRuleList`,
    R.map((entity: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity),
      entity,
    ), data),
    state,
  )
);

const getParentBranchesByBranchGuidSuccess = (state: Object, data: Array) => (
  P.$set(`${GC.CARRIER_CONFIG_GROUP}.options.parentBranches`, data, state)
);

const createCarrierDeactivationRuleSuccess = (state: Object, rule: Object) => (
  P.$add(`${GC.CARRIER_CONFIG_GROUP}.deactivationRuleList`, rule, state)
);

const updateCarrierDeactivationRuleSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.CARRIER_CONFIG_GROUP}.deactivationRuleList.${getDeactivationRuleIndexFromStore(data.guid, state)}`,
    data,
    state,
  )
);

const deleteCarrierDeactivationRuleSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.CARRIER_CONFIG_GROUP}.deactivationRuleList.${getDeactivationRuleIndexFromStore(guid, state)}`, state)
);

const createAccessorialSuccess = (state: Object, data: Object) => (
  P.$add(`${GC.GENERAL_CONFIG_GROUP}.accessorialsList`, data, state)
);

const updateAccessorialSuccess = (state: Object, data: Object) => {
  const stateAccessorials = P.$get(`${GC.GENERAL_CONFIG_GROUP}.accessorialsList`, state);
  const mappedAccessorials = stateAccessorials.map((item: Object) => (
    G.ifElse(
      R.equals(item.guid, data.guid),
      data,
      item,
    )
  ));
  return P.$set(`${GC.GENERAL_CONFIG_GROUP}.accessorialsList`, mappedAccessorials, state);
};

const deleteAccessorialSuccess = (state: Object, guid: string) => {
  const filter = (item: Object) => G.notContain(item.guid, guid);
  const accessorials = R.filter(filter, state[GC.GENERAL_CONFIG_GROUP].accessorialsList);
  return P.$set(`${GC.GENERAL_CONFIG_GROUP}.accessorialsList`, accessorials, state);
};

const getCustomerInvoiceStatusesSuccess = (state: Object, data: Array) => {
  const options = R.map((item: Object) => ({
    ...item,
    [GC.FIELD_LABEL]: G.getDisplayedValueFromObject(item),
    [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
  }), data);

  return (
    P.$all(
      P.$set(`${GC.INVOICE_CONFIG_GROUP}.customerInvoiceStatuses`, data),
      P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.customerInvoiceStatuses`, options),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.customerInvoiceStatuses`, options),
      state,
    )
  );
};

const createCustomerInvoiceStatusesSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'customerInvoiceStatuses');

const updateCustomerInvoiceStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'customerInvoiceStatuses');

const deleteCustomerInvoiceStatusSuccess = (state: Object, guid: string) =>
  removeInvoiceStatusToConfigStoreByInvoiceName(state, guid, 'customerInvoiceStatuses');

const getCarrierInvoiceStatusesSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.carrierInvoiceStatuses`, data),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.carrierInvoiceStatuses`,
      R.map((item: Object) => R.mergeRight(
        item,
        {
          [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
          [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
        },
      ), data),
    ),
    state,
  )
);

const createCarrierInvoiceStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'carrierInvoiceStatuses');

const updateCarrierInvoiceStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'carrierInvoiceStatuses');

const deleteCarrierInvoiceStatusSuccess = (state: Object, guid: string) =>
  removeInvoiceStatusToConfigStoreByInvoiceName(state, guid, 'carrierInvoiceStatuses');

const getFleetServiceInvoiceStatusesSuccess = (state: Object, data: Array) => (
  P.$all(
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.fleetServiceInvoiceStatuses`, data),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.fleetServiceInvoiceStatuses`,
      R.map((item: Object) => R.mergeRight(
        item,
        {
          [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
          [GC.FIELD_VALUE]: G.getPropFromObject(GC.FIELD_ORIGINAL_CONFIG_GUID, item),
        },
      ), data),
    ),
    state,
  )
);

const createOrUpdateFleetServiceInvoiceStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'fleetServiceInvoiceStatuses');

const deleteFleetServiceInvoiceStatusSuccess = (state: Object, guid: string) =>
  removeInvoiceStatusToConfigStoreByInvoiceName(state, guid, 'fleetServiceInvoiceStatuses');

const getDriverPayrollStatusesSuccess = (state: Object, data: Array) => {
  if (G.isNilOrEmpty(data)) return state;

  const mapped = data.map((obj: Object) => ({
    ...obj,
    label: obj.displayedValue,
    value: G.getParentGuidOrGuidFromObject(obj),
  }));

  return P.$all(
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.driverPayrollStatuses`, data),
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.driverPayrollStatuses`, mapped),
    P.$set(`${GC.MOBILEAPP_CONFIG_GROUP}.options.driverPayrollStatuses`, mapped),
    state,
  );
};

const createDriverPayrollStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'driverPayrollStatuses');

const updateDriverPayrollStatusSuccess = (state: Object, data: Object) =>
  setInvoiceStatusToConfigStoreByInvoiceName(state, data, 'driverPayrollStatuses');

const deleteDriverPayrollStatusSuccess = (state: Object, guid: string) =>
  removeInvoiceStatusToConfigStoreByInvoiceName(state, guid, 'driverPayrollStatuses');

const getStatusMessagesForConfigSuccess = (state: Object, data: Array) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.statusMessages`, data, state)
);

const getDocumentsForConfigSuccess = (state: Object, data: Array) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsConfig`, data, state)
);

const createStatusMessageSuccess = (state: Object, data: Object) => (
  P.$add(`${GC.COMMUNICATION_CONFIG_GROUP}.statusMessages`, data, state)
);

const createDocumentSuccess = (state: Object, data: Object) => (
  P.$add(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsConfig`, data, state)
);

const setDocumentsFilter = (state: Object, filter: string) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsFilter`, filter, state)
);

const createNotificationSuccess = (state: Object, data: Object) => (
  P.$add(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationList`, data, state)
);

const updateStatusMessageSuccess = (state: Object, data: Object) => {
  const statuses = P.$get(`${GC.COMMUNICATION_CONFIG_GROUP}.statusMessages`, state);
  const mappedStatuses = statuses.map((item: Object) => (
    G.ifElse(
      R.equals(item.guid, data.guid),
      data,
      item,
    )
  ));
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.statusMessages`, mappedStatuses, state);
};

const updateDocumentSuccess = (state: Object, data: Object) => {
  const docs = P.$get(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsConfig`, state);
  const documents = docs.map((item: Object) => (
    G.ifElse(
      R.equals(item.guid, data.guid),
      data,
      item,
    )
  ));
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsConfig`, documents, state);
};

const updateNotificationConfigsSuccess = (state: Object, data: Object) => {
  const docs = P.$get(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationList`, state);
  const notification = docs.map((item: Object) => (
    G.ifElse(
      R.equals(item.guid, data.guid),
      data,
      item,
    )
  ));
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationList`, notification, state);
};

const deleteStatusMessageSuccess = (state: Object, guid: string) => {
  const filter = (item: Object) => G.notContain(item.guid, guid);
  const statusMessages = R.filter(
    filter,
    state[GC.COMMUNICATION_CONFIG_GROUP].statusMessages,
  );
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.statusMessages`, statusMessages, state);
};

const deleteDocumentConfigSuccess = (state: Object, guid: string) => {
  const filter = (item: Object) => G.notContain(item.guid, guid);
  const documents = R.filter(
    filter,
    state[GC.COMMUNICATION_CONFIG_GROUP].documentsConfig,
  );
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.documentsConfig`, documents, state);
};

const deleteNotificationSuccess = (state: Object, guid: string) => {
  const filter = (item: Object) => G.notContain(item.guid, guid);
  const notification = R.filter(
    filter,
    state[GC.COMMUNICATION_CONFIG_GROUP].notificationList,
  );
  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationList`, notification, state);
};

const connectToFinancialSuccess = (state: Object, data: Object) => (
  P.$set(`financial.${data.type}`, data, state)
);

const getNotificationTriggersSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationConfig`, data, state)
);

const getNotificationListTriggersSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationList`, data, state)
);

const setNotificationTriggersFilter = (state: Object, filter: string) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationTriggersFilter`, filter, state)
);

const setNotificationObjectTypeFilter = (state: Object, filter: string) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.notificationObjectTypeFilter`, filter, state)
);

const getStatusCodeListSuccess = (
  state: Object,
  {
    statusCodeList,
    allStatusCodeList,
    trackingStatusCodeList,
    dropCompletedStatusCodes,
    pickupCompletedStatusCodes,
    allStatusCodeListWithStoredValues,
  }: Object,
) => (
  P.$all(
    P.$set(`${GC.DRIVER_CONFIG_GROUP}.options.statusCodeList`, statusCodeList),
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.statusCodeList`, statusCodeList),
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.allStatusCodeList`, allStatusCodeList),
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.trackingStatusCodeList`, trackingStatusCodeList),
    P.$set(`${GC.CARRIER_CONFIG_GROUP}.options.dropCompletedStatusCodes`, dropCompletedStatusCodes),
    P.$set(`${GC.CARRIER_CONFIG_GROUP}.options.pickupCompletedStatusCodes`, pickupCompletedStatusCodes),
    P.$set(
      `${GC.INTEGRATION_CONFIG_GROUP}.options.allStatusCodeListWithStoredValues`,
      allStatusCodeListWithStoredValues,
    ),
    state,
  )
);

const receivedBranchConfigsByNameSuccess = (state: Object, data: Object) => (
  P.$merge(`${GC.INTEGRATION_CONFIG_GROUP}.options`, data, state)
);

const receivedAccessorialServiceCodesSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.accessorialServiceCodes`, data, state)
);

const getFuelCardListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.fuelCardList`,
    R.indexBy(R.prop(GC.FIELD_GUID), data),
    state,
  )
);

const getCustomerBranchListSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(`${GC.INVOICE_CONFIG_GROUP}.options.customerBranchList`, data),
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.customerBranchList`, data),
    P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.options.customerBranchList`, data),
    state,
  )
);

const receivedFuelCardSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.fuelCardList.${data.guid}`, data, state)
);

const receivedCarrierRateIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierRateIntegrationList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateCarrierRateIntegrationSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'carrierRateIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);
  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierRateIntegrationList`, newList, state);
};

const removeCarrierRateIntegrationSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.carrierRateIntegrationList.${guid}`, state)
);

const receivedUserMonitoringIntegrationListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.userMonitoringIntegrationList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateUserMonitoringIntegrationSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'userMonitoringIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);
  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.userMonitoringIntegrationList`, newList, state);
};

const removeUserMonitoringIntegrationSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.userMonitoringIntegrationList.${guid}`, state)
);

const getServiceMappingListSuccess = (state: Object, data: Object) => {
  const serviceMappingList = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(R.or(data, []));

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.serviceMappingList`, serviceMappingList, state);
};

const setServiceMappingFilter = (state: Object, filter: string) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.serviceMappingFilter`, filter, state)
);

const createServiceMappingListSuccess = (state: Object, list: Object) => {
  const { serviceMappingList } = state;

  const length = R.length(R.values(serviceMappingList));
  const indexedList = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index: R.add(index, length),
        editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      },
    )),
  )(list);

  return P.$merge(`${GC.INTEGRATION_CONFIG_GROUP}.serviceMappingList`, indexedList, state);
};

const createOrUpdateServiceMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'serviceMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.serviceMappingList`, newList, state);
};

const removeServiceMappingSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.serviceMappingList.${guid}`, state)
);

const getAdvancePaymentListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.advancePaymentList`, list, state);
};

const createOrUpdateAdvancePaymentSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'advancePaymentList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);
  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.advancePaymentList`, newList, state);
};

const removeAdvancePaymentSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.advancePaymentList.${guid}`, state)
);

const getEdiConfigsListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.ediConfigsList`,
    R.indexBy(R.prop(GC.FIELD_GUID), data),
    state,
  )
);

const receivedEdiConfigsSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.ediConfigsList.${data.guid}`, data, state)
);

const getEdiExporterConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.ediExporterConfigList`,
    R.indexBy(R.prop(GC.FIELD_GUID), data),
    state,
  )
);

const receivedEdiExporterConfigSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.ediExporterConfigList.${G.getGuidFromObject(data)}`, data, state)
);

const getEdiExtractorConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.ediExtractorConfigList`,
    R.indexBy(R.prop(GC.FIELD_GUID), data),
    state,
  )
);

const receivedEdiExtractorConfigSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.ediExtractorConfigList.${G.getGuidFromObject(data)}`, data, state)
);

// public clo
const getPublicCLOConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.publicCLOConfig`, [], state);

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.publicCLOConfig`,
    R.of(Array, R.assoc('editable', R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, data), data)),
    state,
  );
};

const removePublicCLOConfigSuccess = (state: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.publicCLOConfig`, [], state)
);

const mapAvailableDocumentTypes = (typeObj: Object) => ({
  ...typeObj,
  label: G.getDisplayedValueFromObject(typeObj),
  value: G.getParentGuidOrGuidFromObject(typeObj),
});

const receivedAvailableDocumentTypesSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.availableDocumentTypes`, data),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.availableDocumentTypes`,
      data.map((typeObj: Object) => mapAvailableDocumentTypes(typeObj)),
    ),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.availableDocumentTypes`,
      data.map((typeObj: Object) => mapAvailableDocumentTypes(typeObj)),
    ),
    P.$set(
      `${GC.TEL_CONFIG_GROUP}.options.availableDocumentTypes`,
      data.map((typeObj: Object) => mapAvailableDocumentTypes(typeObj)),
    ),
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.availableDocumentTypes`,
      data.map((typeObj: Object) => mapAvailableDocumentTypes(typeObj)),
    ),
    P.$set(
      `${GC.CARRIER_CONFIG_GROUP}.options.availableDocumentTypes`,
      data.map((typeObj: Object) => mapAvailableDocumentTypes(typeObj)),
    ),
    state,
  )
);

const getStatusMessageCodesSuccess = (state: Object, data: Object) => {
  const getStatusMessageCodeOptionsByStatusType = (statusType: string) => R.compose(
    R.map((item: Object) => ({
      [GC.FIELD_VALUE]: G.getParentGuidOrGuidFromObject(item),
      [GC.FIELD_LABEL]: G.getPropFromObject(GC.FIELD_DISPLAYED_VALUE, item),
    })),
    R.filter(R.propEq(statusType, GC.FIELD_STATUS_TYPE)),
  )(data);

  return P.$all(
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.dropCompletedStatusMessageCodes`,
      getStatusMessageCodeOptionsByStatusType(GC.STATUS_CODE_STATUS_TYPE_DROP_COMPLETED),
    ),
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.pickupCompletedStatusMessageCodes`,
      getStatusMessageCodeOptionsByStatusType(GC.STATUS_CODE_STATUS_TYPE_PICKUP_COMPLETED),
    ),
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.checkedInCompletedStatusMessageCodes`,
      getStatusMessageCodeOptionsByStatusType(GC.STATUS_CODE_STATUS_TYPE_STOP_CHECKED_IN),
    ),
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.pickupCheckedInStatusMessageCodes`,
      getStatusMessageCodeOptionsByStatusType(GC.STATUS_CODE_STATUS_TYPE_PICKUP_CHECKED_IN),
    ),
    P.$set(
      `${GC.MOBILEAPP_CONFIG_GROUP}.options.dropCheckedInStatusMessageCodes`,
      getStatusMessageCodeOptionsByStatusType(GC.STATUS_CODE_STATUS_TYPE_DROP_CHECKED_IN),
    ),
    state,
  );
};

const setConfigWarning = (state: Object, { tabName, message, configGroup }: Object) => (
  P.$set(`${configGroup}.warning.${tabName}`, message, state)
);

// qb account mapping
const setQBAccountMappingFilter = (state: Object, filter: string) => (
  P.$set(`${GC.INVOICE_CONFIG_GROUP}.qbAccountMappingFilter`, filter, state)
);

const getQBAccountMappingListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const groupedList = R.compose(
    R.mergeRight(R.path([GC.INVOICE_CONFIG_GROUP, 'qbAccountMappingList'], initialState)),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.map((item: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      item,
    )),
  )(data);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.qbAccountMappingList`, groupedList, state);
};

const createOrUpdateQBAccountMappingSuccess = (state: Object, data: Object) => {
  const { guid, scope } = data;

  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'qbAccountMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);
  if (R.lt(index, 0)) {
    return P.$add(`${GC.INVOICE_CONFIG_GROUP}.qbAccountMappingList.${scope}`, data, state);
  }

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.qbAccountMappingList.${scope}.${index}`, data, state);
};

const removeQBAccountMappingSuccess = (state: Object, { scope, guid }: Object) => {
  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'qbAccountMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  return P.$drop(`${GC.INVOICE_CONFIG_GROUP}.qbAccountMappingList.${scope}.${index}`, state);
};

// customer id mapping
const getCustomerIdMappingListSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.INVOICE_CONFIG_GROUP}.customerIdMappingList`,
    R.map((entity: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity),
      entity,
    ), data),
    state,
  )
);

const removeCustomerIdMappingSuccess = (state: Object, data: string) => {
  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'customerIdMappingList'], state);
  const index = R.findIndex(R.propEq(data, GC.FIELD_GUID), list);

  return P.$drop(`${GC.INVOICE_CONFIG_GROUP}.customerIdMappingList.${index}`, state);
};

const createOrUpdateCustomerIdMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'customerIdMappingList'], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.INVOICE_CONFIG_GROUP}.customerIdMappingList`, data, state);
  }

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.customerIdMappingList.${index}`, data, state);
};

// department id mapping
const getDepartmentIdMappingListSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.INVOICE_CONFIG_GROUP}.departmentIdMappingList`,
    R.map((entity: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity),
      entity,
    ), data),
    state,
  )
);

const removeDepartmentIdMappingSuccess = (state: Object, data: string) => {
  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'departmentIdMappingList'], state);
  const index = R.findIndex(R.propEq(data, GC.FIELD_GUID), list);

  return P.$drop(`${GC.INVOICE_CONFIG_GROUP}.departmentIdMappingList.${index}`, state);
};

const createOrUpdateDepartmentIdMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'departmentIdMappingList'], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.INVOICE_CONFIG_GROUP}.departmentIdMappingList`, data, state);
  }

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.departmentIdMappingList.${index}`, data, state);
};

// sage account mapping
const setSageAccountMappingFilter = (state: Object, filter: string) => (
  P.$set(`${GC.INVOICE_CONFIG_GROUP}.sageAccountMappingFilter`, filter, state)
);

const getSageAccountMappingListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const groupedList = R.compose(
    R.mergeRight(R.path([GC.INVOICE_CONFIG_GROUP, 'sageAccountMappingList'], initialState)),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.map((item: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      item,
    )),
  )(data);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.sageAccountMappingList`, groupedList, state);
};

const createOrUpdateSageAccountMappingSuccess = (state: Object, data: Object) => {
  const { guid, scope } = data;

  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'sageAccountMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.INVOICE_CONFIG_GROUP}.sageAccountMappingList.${scope}`, data, state);
  }

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.sageAccountMappingList.${scope}.${index}`, data, state);
};

const removeSageAccountMappingSuccess = (state: Object, { scope, guid }: Object) => {
  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'sageAccountMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  return P.$drop(`${GC.INVOICE_CONFIG_GROUP}.sageAccountMappingList.${scope}.${index}`, state);
};

// gl code mapping
const setGLCodeMappingFilter = (state: Object, filter: string) => (
  P.$set(`${GC.INVOICE_CONFIG_GROUP}.glCodeMappingFilter`, filter, state)
);

const getGLCodeMappingListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const groupedList = R.compose(
    R.mergeRight(R.path([GC.INVOICE_CONFIG_GROUP, 'glCodeMappingList'], initialState)),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.map((item: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      item,
    )),
  )(data);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.glCodeMappingList`, groupedList, state);
};

const createOrUpdateCLCodeMappingSuccess = (state: Object, data: Object) => {
  const { guid, scope } = data;

  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'glCodeMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);
  if (R.lt(index, 0)) {
    return P.$add(`${GC.INVOICE_CONFIG_GROUP}.glCodeMappingList.${scope}`, data, state);
  }

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.glCodeMappingList.${scope}.${index}`, data, state);
};

const removeGLCodeMappingSuccess = (state: Object, { guid, scope }: Object) => {
  const list = R.pathOr([], [GC.INVOICE_CONFIG_GROUP, 'glCodeMappingList', scope], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  return P.$drop(`${GC.INVOICE_CONFIG_GROUP}.glCodeMappingList.${scope}.${index}`, state);
};

// invoice status rule
const setInvoiceStatusRuleFilter = (state: Object, filter: string) => (
  P.$set(`${GC.INVOICE_CONFIG_GROUP}.invoiceStatusRuleFilter`, filter, state)
);

const getInvoiceStatusRuleListSuccess = (state: Object, data: Object) => {
  const groupedList = R.compose(
    R.mergeRight(R.path([GC.INVOICE_CONFIG_GROUP, 'invoiceStatusRuleList'], initialState)),
    R.groupBy(R.prop(GC.FIELD_SCOPE)),
    R.map((item: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      item,
    )),
  )(data);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.invoiceStatusRuleList`, groupedList, state);
};

const handleGetConfigsForCloConfigSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.orderType`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.CLO_ORDER_TYPE_DROPDOWN),
    ),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.serviceTypes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_TRANSPORTATION_SERVICE_TYPE),
    ),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.transportationModes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_MODE_TRANSPORTATION),
    ),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.equipmentType`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_EQUIPMENTS),
    ),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.documentType`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.COMMUNICATION_DOCUMENT_TYPE),
    ),
    P.$set(
      `${GC.CLO_CONFIG_GROUP}.options.locationType`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.TEMPLATES_LOCATION_TYPE),
    ),
    state,
  )
);

const handleGetGeneralConfigsForTelConfigSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(
      `${GC.TEL_CONFIG_GROUP}.options.serviceTypes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_TRANSPORTATION_SERVICE_TYPE),
    ),
    P.$set(
      `${GC.TEL_CONFIG_GROUP}.options.transportationModes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_MODE_TRANSPORTATION),
    ),
    state,
  )
);

const handleGetConfigsForInvoiceConfigSuccess = (state: Object, data: Object) => (
  P.$all(
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.orderType`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.CLO_ORDER_TYPE_DROPDOWN),
    ),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.transportationModes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_MODE_TRANSPORTATION),
    ),
    P.$set(
      `${GC.INVOICE_CONFIG_GROUP}.options.availableCarrierDocumentTypes`,
      G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.CARRIER_DOCUMENT_TYPE),
    ),
    state,
  )
);

const handleGetBranchConfigsForRatingSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.RATE_ENGINE_CONFIG_GROUP}.options.transportationModes`,
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.GENERAL_MODE_TRANSPORTATION),
    state,
  )
);

const handleGetConfigsForUiConfigSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.UI_CONFIG_GROUP}.options.truckTypes`,
    G.createOptionsFromDropdownConfigWithGuidOrParentGuid(data, GC.TRUCK_TRUCK_TYPE),
    state,
  )
);

const getAccessorialConfigListForTelConfigSuccess = (state: Object, data: Object) => {
  const noFuelAccessorialOptions = R.compose(
    R.map(({ displayedValue, originalConfigGuid }: Object) => ({
      [GC.FIELD_LABEL]: displayedValue,
      [GC.FIELD_VALUE]: originalConfigGuid,
    })),
    R.filter(({ fuelRelated }: Object) => G.isFalse(fuelRelated)),
  )(data);

  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
        GC.FIELD_CHARGE_INTERNAL_EXPENSE,
      ]),
    )(item)),
  )(data);

  return P.$all(
    P.$set(`${GC.TEL_CONFIG_GROUP}.options.noFuelAccessorials`, noFuelAccessorialOptions),
    P.$set(`${GC.TEL_CONFIG_GROUP}.options.accessorialConfigList`, accessorialConfigList),
    state,
  );
};

const getAccessorialConfigListForCloConfigSuccess = (state: Object, data: Object) => {
  const mapper = R.map(({ displayedValue, originalConfigGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: originalConfigGuid,
  }));
  const accessorials = mapper(data);
  const orderAccessorials = R.compose(
    mapper,
    R.filter(({ fuelRelated, internalExpense }: Object) => R.and(
      G.isFalse(fuelRelated),
      R.or(G.isFalse(internalExpense), G.isNilOrEmpty(internalExpense)),
    )),
  )(data);
  const accessorialConfigList = R.compose(
    R.indexBy(R.prop(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID)),
    R.map((item: Object) => R.compose(
      R.assoc(GC.FIELD_ACCESSORIAL_ACCESSORIAL_GUID, R.prop(GC.FIELD_ORIGINAL_CONFIG_GUID, item)),
      R.pick([
        GC.FIELD_NON_TAXABLE,
        GC.FIELD_FUEL_RELATED,
        GC.FIELD_DISPLAYED_VALUE,
        GC.FIELD_CHARGE_RATE_TYPE,
        GC.FIELD_CHARGE_RATE_UNIT,
        GC.FIELD_CHARGE_INTERNAL_EXPENSE,
      ]),
    )(item)),
  )(data);

  return P.$all(
    P.$set(`${GC.CLO_CONFIG_GROUP}.options.accessorials`, accessorials),
    P.$set(`${GC.CLO_CONFIG_GROUP}.options.orderAccessorials`, orderAccessorials),
    P.$set(`${GC.CLO_CONFIG_GROUP}.options.accessorialConfigList`, accessorialConfigList),
    state,
  );
};

const getSharedAccessorialListSuccess = (state: Object, data: Object) => {
  const accessorialConfigList = P.$get(`${GC.CLO_CONFIG_GROUP}.options.accessorialConfigList`, state);
  const mapper = R.map(({ displayedValue, assessorialGuid }: Object) => ({
    [GC.FIELD_LABEL]: displayedValue,
    [GC.FIELD_VALUE]: assessorialGuid,
  }));
  const fuelAccessorials = R.compose(
    mapper,
    R.filter(R.propEq(true, 'fuelRelated')),
    R.values,
  )(accessorialConfigList);
  const sharedMapper = R.map(({ guid, name }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: `${name} (${G.getWindowLocale('titles:shared', 'Shared')})`,
  }));
  const shared = sharedMapper(data);
  const fuelAccessorialOptions = R.concat(shared, fuelAccessorials);

  return P.$all(
    P.$set('sharedAccessorialList', data),
    P.$set(`${GC.CLO_CONFIG_GROUP}.options.fuelAccessorialOptions`, fuelAccessorialOptions),
    state,
  );
};

const getDataSourceListSuccess = (state: Object, data: Object) => {
  const dataSourceList = R.map(({ dataSource }: Object) => dataSource, data);
  const indexedDataSourceList = R.indexBy(
    R.prop(GC.FIELD_CONFIGURATION_COMMUNICATION_DOCUMENT_TEMPLATE_DATA_SOURCE),
    data,
  );
  return P.$all(
    P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.dataSourceList`, dataSourceList),
    P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.indexedDataSourceList`, indexedDataSourceList),
    state,
  );
};

const getIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) {
    return P.$all(
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.integrationConfigList`, []),
      P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.integrationConfigs`, []),
      state,
    );
  }
  const integrationConfigList = R.indexBy(R.prop(GC.FIELD_GUID), data);
  const integrationConfigOptions = R.compose(
    R.map(({ name, guid }: Object) => ({ [GC.FIELD_LABEL]: name, [GC.FIELD_VALUE]: guid })),
  )(data);
  return P.$all(
    P.$set(
      `${GC.INTEGRATION_CONFIG_GROUP}.integrationConfigList`,
      integrationConfigList,
    ),
    P.$set(
      `${GC.INTEGRATION_CONFIG_GROUP}.options.integrationConfigs`,
      integrationConfigOptions,
    ),
    state,
  );
};

const receivedIntegrationConfigSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.integrationConfigList.${data.guid}`, data, state)
);

const getLoadBoardIntegrationConfigListSuccess = (state: Object, data: Object) => {
  const elements = R.prop('elements', data);

  if (G.isNilOrEmpty(elements)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      {
        index,
        editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        deletable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
      },
    )),
  )(elements);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.loadBoardConfigList`, list, state);
};

const createOrUpdateLoadBoardIntegrationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'loadBoardConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.loadBoardConfigList`, newList, state);
};

const removeLoadBoardIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.loadBoardConfigList.${guid}`, state)
);

const updateLoadBoardIntegrationConfigState = (state: Object, data: Object) => {
  const lbConfigs = R.pathOr([], [GC.INTEGRATION_CONFIG_GROUP, 'loadBoardConfigList'], state);

  if (R.isEmpty(lbConfigs)) return state;

  const guid = G.getGuidFromObject(data);
  const loadBoardToUpdate = R.prop(guid, lbConfigs);

  let index;

  if (R.isNil(loadBoardToUpdate)) {
    index = R.length(R.values(lbConfigs));
  } else {
    index = R.prop('index', loadBoardToUpdate);
  }

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.loadBoardConfigList.${guid}`,
    {...data, index},
    state,
  );
};

const getTollChargesConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.tollChargeList`, list, state);
};

const createOrUpdateTollChargesConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'tollChargeList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.tollChargeList`, newList, state);
};

const removeTollChargesConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.tollChargeList.${guid}`, state)
);

const getExpenseTypeListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.expenseTypeList`, list, state);
};

const createOrUpdateExpenseTypeSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const { guid } = data;

  const list = R.path([GC.INVOICE_CONFIG_GROUP, 'expenseTypeList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INVOICE_CONFIG_GROUP}.expenseTypeList`, newList, state);
};

const removeExpenseTypeSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INVOICE_CONFIG_GROUP}.expenseTypeList.${guid}`, state)
);

const getDistanceCalculatorConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) {
    return P.$all(
      P.$set(`${GC.GENERAL_CONFIG_GROUP}.distanceCalculatorConfigList`, []),
      P.$set(`${GC.GENERAL_CONFIG_GROUP}.options.distanceCalculatorConfigOptions`, []),
      state,
    );
  }
  const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();
  const mappedData = R.map(
    (item: Object) => R.assoc(
      'editable',
      R.propEq(currentBranchGuid, GC.BRANCH_GUID, item),
      item,
    ),
    data,
  );
  const distanceCalculatorConfigOptions = G.mapNameGuidObjectPropsToLabelValueObject(mappedData);

  return P.$all(
    P.$set(
      `${GC.GENERAL_CONFIG_GROUP}.distanceCalculatorConfigList`,
      mappedData,
    ),
    P.$set(
      `${GC.GENERAL_CONFIG_GROUP}.options.distanceCalculatorConfigOptions`,
      distanceCalculatorConfigOptions,
    ),
    state,
  );
};

const receivedStatusMessageListForCloSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.CLO_CONFIG_GROUP}.options.statusMessageList`, data, state)
);

const getCrossDockLocationsSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.TEL_CONFIG_GROUP}.options.crossDockLocations`,
    R.map((location: Object) => ({
      value: R.prop(GC.FIELD_TEMPLATE_ID, location),
      label: R.prop(GC.FIELD_TEMPLATE_ID, location),
    }), data),
    state,
  )
);

const receivedCarrierOnboardingListSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageList`, data, state)
);

const deleteCarrierOnboardingPackageSuccess = (state: Object) => (
  P.$all(
    P.$set(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageList`, []),
    P.$set(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageDocumentList`, {}),
    state,
  )
);

const createOrUpdateCarrierOnboardingPackageSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageList`, R.of(Array, data), state)
);

const receivedCarrierOnboardingDocumentListSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageDocumentList`, R.indexBy(R.prop(GC.FIELD_GUID), data), state)
);

const deleteCarrierOnboardingPackageDocumentSuccess = (state: Object, data: Object) => (
  P.$drop(`${GC.CARRIER_CONFIG_GROUP}.onboardingPackageDocumentList.${data}`, state)
);

const createOrUpdateCarrierOnboardingPackageDocumentSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.CARRIER_CONFIG_GROUP}.onboardingPackageDocumentList`,
    R.assoc(
      R.prop(GC.FIELD_GUID, data),
      data,
      R.path([GC.CARRIER_CONFIG_GROUP, 'onboardingPackageDocumentList'], state),
    ),
    state,
  )
);

const getTermsAndConditionsDocumentSuccess = (state: Object, data: Object) => {
  if (R.or(G.isString(data), G.isNilOrEmpty(data))) return P.$set(`${data}.termsAndConditionsDocument`, [], state);

  const configGroup = G.getPropFromObject('configGroup', data);
  const list = R.of(Array,
    R.assoc('editable', R.pathEq(G.getAmousCurrentBranchGuidFromWindow(), [GC.BRANCH_GUID], data), data),
  );

  return P.$set(`${configGroup}.termsAndConditionsDocument`, list, state);
};

const getCloDispatchBoardActionListSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.UI_CONFIG_GROUP}.cloDispatchBoardActionPanel`, data, state)
);

const getTelDispatchBoardActionListSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.UI_CONFIG_GROUP}.telDispatchBoardActionPanel`, data, state)
);

const setAvailableLocationTypes = (state: Object, data: Array) => (
  P.$set(`${GC.TEMPLATES_CONFIG_GROUP}.options.availableLocationTypes`, data, state)
);

// gps integration config
const getGpsIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.gpsIntegrationList`, list, state);
};

const createOrUpdateGpsIntegrationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;
  const { guid } = data;
  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'gpsIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.gpsIntegrationList`, newList, state);
};

const removeGpsIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.gpsIntegrationList.${guid}`, state)
);

// dispatch integration config
const getDispatchIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.dispatchIntegrationList`, list, state);
};

const createOrUpdateDispatchIntegrationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'dispatchIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.dispatchIntegrationList`, newList, state);
};

const removeDispatchIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.dispatchIntegrationList.${guid}`, state)
);

// status code mapping
const getStatusCodeMappingListSuccess = (state: Object, { data, listName }: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.${listName}`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateStatusCodeMappingSuccess = (state: Object, { data, listName }: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, listName], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.${listName}`, newList, state);
};

const removeStatusCodeMappingSuccess = (state: Object, { guid, listName }: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.${listName}.${guid}`, state)
);

// status reason code mapping
const getStatusReasonCodeMappingListSuccess = (state: Object, { data, listName }: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.${listName}`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateStatusReasonCodeMappingSuccess = (state: Object, { data, listName }: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, listName], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.${listName}`, newList, state);
};

const removeStatusReasonCodeMappingSuccess = (state: Object, { guid, listName }: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.${listName}.${guid}`, state)
);

// service type mapping
const getServiceTypeMappingListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.serviceTypeMappingList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateServiceTypeMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'serviceTypeMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.serviceTypeMappingList`, newList, state);
};

const removeServiceTypeMappingSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.serviceTypeMappingList.${guid}`, state)
);

// default order accessorials
const getDefaultOrderAccessorialListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.CLO_CONFIG_GROUP}.defaultOrderAccessorialList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateDefaultOrderAccessorialSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.CLO_CONFIG_GROUP, 'defaultOrderAccessorialList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  G.setItemToWindow('amousDefaultOrderAccessorials', {});

  return P.$set(`${GC.CLO_CONFIG_GROUP}.defaultOrderAccessorialList`, newList, state);
};

const removeDefaultOrderAccessorialSuccess = (state: Object, guid: string) => {
  G.setItemToWindow('amousDefaultOrderAccessorials', {});

  return P.$drop(`${GC.CLO_CONFIG_GROUP}.defaultOrderAccessorialList.${guid}`, state);
};


// default carrier accessorials
const getDefaultCarrierAccessorialListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.TEL_CONFIG_GROUP}.defaultCarrierAccessorialList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateDefaultCarrierAccessorialSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.TEL_CONFIG_GROUP, 'defaultCarrierAccessorialList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);
  G.setItemToWindow('amousDefaultCarrierAccessorials', {});

  return P.$set(`${GC.TEL_CONFIG_GROUP}.defaultCarrierAccessorialList`, newList, state);
};

const removeDefaultCarrierAccessorialSuccess = (state: Object, guid: string) => {
  G.setItemToWindow('amousDefaultCarrierAccessorials', {});

  return P.$drop(`${GC.TEL_CONFIG_GROUP}.defaultCarrierAccessorialList.${guid}`, state);
};

// carrier synchronization
const getCarrierSynchronizationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const editable = R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, data);

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierSynchronization`,
    R.of(Array, R.assoc('editable', editable, data)),
    state,
  );
};

const removeCarrierSynchronizationConfigSuccess = (state: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierSynchronization`, [], state)
);

// carrier integration override
const getCarrierIntegrationOverrideListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierIntegrationOverrideList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateCarrierIntegrationOverrideSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'carrierIntegrationOverrideList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierIntegrationOverrideList`, newList, state);
};

const removeCarrierIntegrationOverrideSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.carrierIntegrationOverrideList.${guid}`, state)
);

// integration mail config
const getIntegrationMailConfigSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.COMMUNICATION_CONFIG_GROUP}.integrationMailConfig`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight({ index, deletable: item.editable }, item)),
    )(R.or(data, [])),
    state,
  )
);

// mail template config
const getMailTemplateConfigSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.COMMUNICATION_CONFIG_GROUP}.mailTemplateConfig`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight({ index, deletable: item.editable }, item)),
    )(R.or(data, [])),
    state,
  )
);
const getMailTemplateTypesSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.mailTemplateTypes`, data, state)
);

const getMailTemplateNamesByTypeSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.mailTemplateNames`, data, state)
);

const getMailTemplateByKeySuccess = (state: Object, data: Object) => (
  P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.mailTemplateByKey`, data, state)
);

// document type mapping
const getDocumentTypeMappingListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.documentTypeMappingList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateDocumentTypeMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'documentTypeMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.documentTypeMappingList`, newList, state);
};

const removeDocumentTypeMappingSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.documentTypeMappingList.${guid}`, state)
);

// document hub config
const getDocumentHubConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.documentHubConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateDocumentHubConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'documentHubConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.documentHubConfigList`, newList, state);
};

const removeDocumentHubConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.documentHubConfigList.${guid}`, state)
);

// accounting
const getAccountingConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.accountingConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateAccountingConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'accountingConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.accountingConfigList`, newList, state);
};

const removeAccountingConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.accountingConfigList.${guid}`, state)
);

// carrier pickup requester
const getCarrierPickupRequesterListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierPickupRequesterList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
          deletable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateCarrierPickupRequesterSuccess = (state: Object, data: Object) => {
  const { guid, name } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'carrierPickupRequesterList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$all(
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierPickupRequesterList`, newList),
    P.$set(
      `${GC.INTEGRATION_CONFIG_GROUP}.options.availableCarrierPickupRequesterList.${guid}`,
      { guid, name },
    ),
    state,
  );
};

const removeCarrierPickupRequesterSuccess = (state: Object, guid: string) => (
  P.$all(
    P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.carrierPickupRequesterList.${guid}`),
    P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.options.availableCarrierPickupRequesterList.${guid}`),
    state,
  )
);

const getAvailableCarrierPickupRequesterListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.options.availableCarrierPickupRequesterList`,
    R.indexBy(R.prop(GC.FIELD_GUID), data),
    state,
  )
);

// carrier edi loader config
const getCarrierEdiLoaderConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiLoaderConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateCarrierEdiLoaderConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'carrierEdiLoaderConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiLoaderConfigList`, newList, state);
};

const removeCarrierEdiLoaderConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiLoaderConfigList.${guid}`, state)
);

const getCarrierOptionsSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.options.carriers`,
    G.addEmptyOptionToDropDown(R.map(({ guid, name }: Object) => ({
      [GC.FIELD_VALUE]: guid,
      [GC.FIELD_LABEL]: name,
    }), R.or(data, []))),
    state,
  )
);

// carrier edi exporter config
const getCarrierEdiExporterConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiExporterConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateCarrierEdiExporterConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'carrierEdiExporterConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiExporterConfigList`, newList, state);
};

const removeCarrierEdiExporterConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.carrierEdiExporterConfigList.${guid}`, state)
);

// transportation mode config mapping
const getTransportationModeConfigMappingListSuccess = (state: Object, { data, configGroup }: Object) => (
  P.$set(
    `${configGroup}.transportationModeConfigMappingList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(R.or(data, [])),
    state,
  )
);

const createOrUpdateTransportationModeConfigMappingSuccess = (state: Object, data: Object) => {
  const { guid, configGroup } = data;

  const list = R.path([configGroup, 'transportationModeConfigMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${configGroup}.transportationModeConfigMappingList`, newList, state);
};

const removeTransportationModeConfigMappingSuccess = (state: Object, { guid, configGroup }: string) => (
  P.$drop(`${configGroup}.transportationModeConfigMappingList.${guid}`, state)
);

// vin lookup config
const getVinLookupConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.vinLookupConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(R.of(Array, data)),
    state,
  );
};

const createOrUpdateVinLookupConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'vinLookupConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.vinLookupConfigList`, newList, state);
};

const removeVinLookupConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.vinLookupConfigList.${guid}`, state)
);

// load custom status config
const getLoadCustomStatusListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.GENERAL_CONFIG_GROUP}.loadCustomStatusConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateLoadCustomStatusSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.GENERAL_CONFIG_GROUP, 'loadCustomStatusConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.GENERAL_CONFIG_GROUP}.loadCustomStatusConfigList`, newList, state);
};

const removeLoadCustomStatusSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.GENERAL_CONFIG_GROUP}.loadCustomStatusConfigList.${guid}`, state)
);

// factoring integration config
const getFactoringIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.factoringIntegrationConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateFactoringIntegrationConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'factoringIntegrationConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.factoringIntegrationConfigList`, newList, state);
};

const removeFactoringIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.factoringIntegrationConfigList.${guid}`, state)
);

// equipment mapping config
const getEquipmentMappingConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.equipmentMappingConfigList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateEquipmentMappingConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'equipmentMappingConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.equipmentMappingConfigList`, newList, state);
};

const removeEquipmentMappingConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.equipmentMappingConfigList.${guid}`, state)
);

// available edi export mappers
const getAvailableEdiExportMappersSuccess = (state: Object, mappers: Array) => {
  const getOptions = (availableMappers: Array) => G.addEmptyOptionToDropDown(
    R.filter(({ value }: Object) => R.includes(value, R.or(mappers, [])), availableMappers),
  );

  const optionsForExporter = getOptions(availableEdiExportMappers);
  const optionsForLoader = getOptions(R.concat(
    availableEdiExportMappers,
    availableEdiLoaderExportMappers,
  ));

  return P.$all(
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.availableEdiExportMappers`, optionsForExporter),
    P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.options.availableEdiExportMappersForLoader`, optionsForLoader),
    state,
  );
};

// margin violation rule
const getMarginViolationRuleListSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.RATE_ENGINE_CONFIG_GROUP}.marginViolationRuleList`,
    R.map((entity: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity),
      entity,
    ), data),
    state,
  )
);

const removeMarginViolationRuleSuccess = (state: Object, data: string) => {
  const list = R.pathOr([], [GC.RATE_ENGINE_CONFIG_GROUP, 'marginViolationRuleList'], state);
  const index = R.findIndex(R.propEq(data, GC.FIELD_GUID), list);

  return P.$drop(`${GC.RATE_ENGINE_CONFIG_GROUP}.marginViolationRuleList.${index}`, state);
};

const createOrUpdateMarginViolationRuleSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.pathOr([], [GC.RATE_ENGINE_CONFIG_GROUP, 'marginViolationRuleList'], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.RATE_ENGINE_CONFIG_GROUP}.marginViolationRuleList`, data, state);
  }

  return P.$set(`${GC.RATE_ENGINE_CONFIG_GROUP}.marginViolationRuleList.${index}`, data, state);
};

// onboarding integration config
const getOnboardingIntegrationConfigListSuccess = (state: Object, data: Array) => (
  P.$set(
    `${GC.DRIVER_CONFIG_GROUP}.onboardingIntegrationList`,
    R.map((item: Object) => R.assoc('deletable', item.editable, item), R.or(data, [])),
    state,
  )
);

// trailer tracking integration config
const getTrailerTrackingIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.trailerTrackingIntegrationList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          deletable: item.editable,
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateTrailerTrackingIntegrationConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'trailerTrackingIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.mergeRight(data, { index, deletable: data.editable });
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.trailerTrackingIntegrationList`, newList, state);
};

const removeTrailerTrackingIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.trailerTrackingIntegrationList.${guid}`, state)
);

// los subscription config
const getLosSubscriptionConfigSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.losSubscriptionConfig`, data, state)
);

const updateLosSubscriptionConfigSuccess = (state: Object, data: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.losSubscriptionConfig`, data, state)
);

const unlinkLosAccountSuccess = (state: Object) => (
  P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.initialValues.${GC.INTEGRATION_LOS_ACCOUNT_LINKED}`, false, state)
);

// los pricing plan
const getLosPricingPlanConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.losPricingPlanList`,
    G.indexByGuid(R.or(data, [])),
    state,
  )
);

const createOrUpdateLosPricingPlanConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'losPricingPlanList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.losPricingPlanList`, newList, state);
};

const removeLosPricingPlanConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.losPricingPlanList.${guid}`, state)
);

// carrier scorecard config
const getCarrierScorecardConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.CARRIER_CONFIG_GROUP}.carrierScorecardConfigList`,
    R.map((entity: Object) => R.assoc(
      'editable',
      R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, entity),
      entity,
    ), data),
    state,
  )
);

const removeCarrierScorecardConfigSuccess = (state: Object, data: string) => {
  const list = R.pathOr([], [GC.CARRIER_CONFIG_GROUP, 'carrierScorecardConfigList'], state);
  const index = R.findIndex(R.propEq(data, GC.FIELD_GUID), list);

  return P.$drop(`${GC.CARRIER_CONFIG_GROUP}.carrierScorecardConfigList.${index}`, state);
};

const createOrUpdateCarrierScoreCardconfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.pathOr([], [GC.CARRIER_CONFIG_GROUP, 'carrierScorecardConfigList'], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.CARRIER_CONFIG_GROUP}.carrierScorecardConfigList`, data, state);
  }

  return P.$set(`${GC.CARRIER_CONFIG_GROUP}.carrierScorecardConfigList.${index}`, data, state);
};

// terminal integration config
const getTerminalIntegrationConfigListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.terminalIntegrationList`, list, state);
};

const createOrUpdateTerminalIntegrationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'terminalIntegrationList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.terminalIntegrationList`, newList, state);
};

const removeTerminalIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.terminalIntegrationList.${guid}`, state)
);

// task type config
const getTaskTypeListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskTypeList`, list, state);
};

const createOrUpdateTaskTypeSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.TASK_MANAGEMENT_CONFIG_GROUP, 'taskTypeList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskTypeList`, newList, state);
};

const removeTaskTypeSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskTypeList.${guid}`, state)
);

// task status config
const getTaskStatusListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskStatusList`, list, state);
};

const createOrUpdateTaskStatusSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.TASK_MANAGEMENT_CONFIG_GROUP, 'taskStatusList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskStatusList`, newList, state);
};

const removeTaskStatusSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.TASK_MANAGEMENT_CONFIG_GROUP}.taskStatusList.${guid}`, state)
);

// document sending config
const getDocumentSendingConfigListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.COMMUNICATION_CONFIG_GROUP}.documentSendingList`,
    R.map(
      (item: Object) => R.assoc(
        'editable',
        R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        item,
      ),
      R.or(data, []),
    ),
    state,
  )
);

const createOrUpdateDocumentSendingConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.pathOr([], [GC.COMMUNICATION_CONFIG_GROUP, 'documentSendingList'], state);
  const index = R.findIndex(R.propEq(guid, GC.FIELD_GUID), list);

  if (R.lt(index, 0)) {
    return P.$add(`${GC.COMMUNICATION_CONFIG_GROUP}.documentSendingList`, data, state);
  }

  return P.$set(`${GC.COMMUNICATION_CONFIG_GROUP}.documentSendingList.${index}`, data, state);
};

const removeDocumentSendingConfigSuccess = (state: Object, guid: string) => {
  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    R.path([GC.COMMUNICATION_CONFIG_GROUP, 'documentSendingList'], state),
  );

  return P.$drop(`${GC.COMMUNICATION_CONFIG_GROUP}.documentSendingList.${index}`, state);
};

// transportation mode grouping
const getTransportationModeGroupingListSuccess = (state: Object, data: Object) => (
  P.$set(
    `${GC.GENERAL_CONFIG_GROUP}.transportationModeGroupingList`,
    R.map((item: Object) =>
      R.assoc('editable', R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item), item),
      data,
    ),
    state,
  )
);

const removeTransportationModeGroupingSuccess = (state: Object, data: string) => {
  const index = R.findIndex(
    R.propEq(data, GC.FIELD_GUID),
    R.pathOr([], [GC.GENERAL_CONFIG_GROUP, 'transportationModeGroupingList'], state),
  );

  return P.$drop(`${GC.GENERAL_CONFIG_GROUP}.transportationModeGroupingList.${index}`, state);
};

const createOrUpdateTransportationModeGroupingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const index = R.findIndex(
    R.propEq(guid, GC.FIELD_GUID),
    R.pathOr([], [GC.GENERAL_CONFIG_GROUP, 'transportationModeGroupingList'], state),
  );

  if (R.lt(index, 0)) {
    return P.$add(`${GC.GENERAL_CONFIG_GROUP}.transportationModeGroupingList`, data, state);
  }

  return P.$set(`${GC.GENERAL_CONFIG_GROUP}.transportationModeGroupingList.${index}`, data, state);
};

// cross border integration config
const getCrossBorderIntegrationConfigSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  const list = R.compose(
    R.indexBy(R.prop(GC.FIELD_GUID)),
    G.mapIndexed((item: Object, index: number) => R.mergeRight(
      item,
      { index, editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item) },
    )),
  )(data);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.crossBorderIntegrationConfigList`, list, state);
};

const createOrUpdateCrossBorderIntegrationConfigSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'crossBorderIntegrationConfigList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.mergeRight(data, { index, deletable: data.editable });
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.crossBorderIntegrationConfigList`, newList, state);
};

const removeCrossBorderIntegrationConfigSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.crossBorderIntegrationConfigList.${guid}`, state)
);

// truck type mapping
const getTruckTypeMappingListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.truckTypeMappingList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateTruckTypeMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'truckTypeMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.truckTypeMappingList`, newList, state);
};

const removeTruckTypeMappingSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.truckTypeMappingList.${guid}`, state)
);

// trailer type mapping
const getTrailerTypeMappingListSuccess = (state: Object, data: Object) => {
  if (G.isNilOrEmpty(data)) return state;

  return P.$set(
    `${GC.INTEGRATION_CONFIG_GROUP}.trailerTypeMappingList`,
    R.compose(
      R.indexBy(R.prop(GC.FIELD_GUID)),
      G.mapIndexed((item: Object, index: number) => R.mergeRight(
        item,
        {
          index,
          editable: R.propEq(G.getAmousCurrentBranchGuidFromWindow(), GC.BRANCH_GUID, item),
        },
      )),
    )(data),
    state,
  );
};

const createOrUpdateTrailerTypeMappingSuccess = (state: Object, data: Object) => {
  const { guid } = data;

  const list = R.path([GC.INTEGRATION_CONFIG_GROUP, 'trailerTypeMappingList'], state);
  const index = R.pathOr(R.length(R.values(list)), [guid, 'index'], list);
  const newListItem = R.assoc('index', index, data);
  const newList = R.assoc(guid, newListItem, list);

  return P.$set(`${GC.INTEGRATION_CONFIG_GROUP}.trailerTypeMappingList`, newList, state);
};

const removeTrailerTypeMappingSuccess = (state: Object, guid: string) => (
  P.$drop(`${GC.INTEGRATION_CONFIG_GROUP}.trailerTypeMappingList.${guid}`, state)
);

//
const cleanConfigState = () => initialState;

export default createReducer({
  [A.cleanConfigState]: cleanConfigState,
  [A.setConfigWarning]: setConfigWarning,
  [A.setDocumentsFilter]: setDocumentsFilter,
  [A.setConfigTabsVisited]: setConfigTabsVisited,
  [A.createDocumentSuccess]: createDocumentSuccess,
  [A.updateDocumentSuccess]: updateDocumentSuccess,
  [A.getFuelCardListSuccess]: getFuelCardListSuccess,
  [A.receivedFuelCardSuccess]: receivedFuelCardSuccess,
  [A.getConfigByGroupSuccess]: getConfigByGroupSuccess,
  [A.setServiceMappingFilter]: setServiceMappingFilter,
  [A.getEdiConfigsListSuccess]: getEdiConfigsListSuccess,
  [A.getDataSourceListSuccess]: getDataSourceListSuccess,
  [A.createAccessorialSuccess]: createAccessorialSuccess,
  [A.updateAccessorialSuccess]: updateAccessorialSuccess,
  [A.deleteAccessorialSuccess]: deleteAccessorialSuccess,
  [A.getStatusCodeListSuccess]: getStatusCodeListSuccess,
  [A.setAvailableLocationTypes]: setAvailableLocationTypes,
  [A.receivedEdiConfigsSuccess]: receivedEdiConfigsSuccess,
  [A.createNotificationSuccess]: createNotificationSuccess,
  [A.deleteNotificationSuccess]: deleteNotificationSuccess,
  [A.connectToFinancialSuccess]: connectToFinancialSuccess,
  [A.createStatusMessageSuccess]: createStatusMessageSuccess,
  [A.updateStatusMessageSuccess]: updateStatusMessageSuccess,
  [A.deleteStatusMessageSuccess]: deleteStatusMessageSuccess,
  [A.deleteDocumentConfigSuccess]: deleteDocumentConfigSuccess,
  [A.removeAdvancePaymentSuccess]: removeAdvancePaymentSuccess,
  [A.removeServiceMappingSuccess]: removeServiceMappingSuccess,
  [A.getCustomerBranchListSuccess]: getCustomerBranchListSuccess,
  [A.getDocumentsForConfigSuccess]: getDocumentsForConfigSuccess,
  [A.getCrossDockLocationsSuccess]: getCrossDockLocationsSuccess,
  [A.getStatusMessageCodesSuccess]: getStatusMessageCodesSuccess,
  [A.getServiceMappingListSuccess]: getServiceMappingListSuccess,
  [A.getAdvancePaymentListSuccess]: getAdvancePaymentListSuccess,
  [A.setNotificationTriggersFilter]: setNotificationTriggersFilter,
  [A.removeTollChargesConfigSuccess]: removeTollChargesConfigSuccess,
  [A.getNotificationTriggersSuccess]: getNotificationTriggersSuccess,
  [A.getSequencesGuidNameMapSuccess]: getSequencesGuidNameMapSuccess,
  [A.getAccessorialForConfigSuccess]: getAccessorialForConfigSuccess,
  [A.getConfigsForUiConfigSuccess]: handleGetConfigsForUiConfigSuccess,
  [A.setNotificationObjectTypeFilter]: setNotificationObjectTypeFilter,
  [A.getSharedAccessorialListSuccess]: getSharedAccessorialListSuccess,
  [A.createServiceMappingListSuccess]: createServiceMappingListSuccess,
  [A.getEdiExporterConfigListSuccess]: getEdiExporterConfigListSuccess,
  [A.getTollChargesConfigListSuccess]: getTollChargesConfigListSuccess,
  [A.getIntegrationConfigListSuccess]: getIntegrationConfigListSuccess,
  [A.getDriverPayrollStatusesSuccess]: getDriverPayrollStatusesSuccess,
  [getConfigsForCloConfigSuccess]: handleGetConfigsForCloConfigSuccess,
  [socketLBConfigStateReceived]: updateLoadBoardIntegrationConfigState,
  [A.receivedEdiExporterConfigSuccess]: receivedEdiExporterConfigSuccess,
  [A.getEdiExtractorConfigListSuccess]: getEdiExtractorConfigListSuccess,
  [A.receivedIntegrationConfigSuccess]: receivedIntegrationConfigSuccess,
  [A.getCarrierInvoiceStatusesSuccess]: getCarrierInvoiceStatusesSuccess,
  [A.updateNotificationConfigsSuccess]: updateNotificationConfigsSuccess,
  [A.createDriverPayrollStatusSuccess]: createDriverPayrollStatusSuccess,
  [A.updateDriverPayrollStatusSuccess]: updateDriverPayrollStatusSuccess,
  [A.deleteDriverPayrollStatusSuccess]: deleteDriverPayrollStatusSuccess,
  [A.removeGpsIntegrationConfigSuccess]: removeGpsIntegrationConfigSuccess,
  [A.receivedEdiExtractorConfigSuccess]: receivedEdiExtractorConfigSuccess,
  [A.getCustomerInvoiceStatusesSuccess]: getCustomerInvoiceStatusesSuccess,
  [A.createCarrierInvoiceStatusSuccess]: createCarrierInvoiceStatusSuccess,
  [A.updateCarrierInvoiceStatusSuccess]: updateCarrierInvoiceStatusSuccess,
  [A.deleteCarrierInvoiceStatusSuccess]: deleteCarrierInvoiceStatusSuccess,
  [A.getStatusMessagesForConfigSuccess]: getStatusMessagesForConfigSuccess,
  [A.getGpsIntegrationConfigListSuccess]: getGpsIntegrationConfigListSuccess,
  [A.getSequencesByTypeForConfigSuccess]: getSequencesByTypeForConfigSuccess,
  [A.getNotificationListTriggersSuccess]: getNotificationListTriggersSuccess,
  [A.updateCustomerInvoiceStatusSuccess]: updateCustomerInvoiceStatusSuccess,
  [A.deleteCustomerInvoiceStatusSuccess]: deleteCustomerInvoiceStatusSuccess,
  [A.receivedBranchConfigsByNameSuccess]: receivedBranchConfigsByNameSuccess,
  [getBranchConfigsForRatingSuccess]: handleGetBranchConfigsForRatingSuccess,
  [getConfigsForInvoiceConfigSuccess]: handleGetConfigsForInvoiceConfigSuccess,
  [A.createOrUpdateServiceMappingSuccess]: createOrUpdateServiceMappingSuccess,
  [A.createOrUpdateAdvancePaymentSuccess]: createOrUpdateAdvancePaymentSuccess,
  [A.removeCarrierRateIntegrationSuccess]: removeCarrierRateIntegrationSuccess,
  [A.deleteCarrierDeactivationRuleSuccess]: deleteCarrierDeactivationRuleSuccess,
  [A.updateCarrierDeactivationRuleSuccess]: updateCarrierDeactivationRuleSuccess,
  [A.createCarrierDeactivationRuleSuccess]: createCarrierDeactivationRuleSuccess,
  [A.getTermsAndConditionsDocumentSuccess]: getTermsAndConditionsDocumentSuccess,
  [A.receivedCarrierOnboardingListSuccess]: receivedCarrierOnboardingListSuccess,
  [A.createCustomerInvoiceStatusesSuccess]: createCustomerInvoiceStatusesSuccess,
  [A.getCloDispatchBoardActionListSuccess]: getCloDispatchBoardActionListSuccess,
  [A.getParentBranchesByBranchGuidSuccess]: getParentBranchesByBranchGuidSuccess,
  [A.getTelDispatchBoardActionListSuccess]: getTelDispatchBoardActionListSuccess,
  [A.receivedAvailableDocumentTypesSuccess]: receivedAvailableDocumentTypesSuccess,
  [A.getReferenceTypesGuidsNamesMapSuccess]: getReferenceTypesGuidsNamesMapSuccess,
  [A.getFleetServiceInvoiceStatusesSuccess]: getFleetServiceInvoiceStatusesSuccess,
  [A.deleteCarrierOnboardingPackageSuccess]: deleteCarrierOnboardingPackageSuccess,
  [A.getCarrierDeactivationRuleListSuccess]: getCarrierDeactivationRuleListSuccess,
  [A.deleteFleetServiceInvoiceStatusSuccess]: deleteFleetServiceInvoiceStatusSuccess,
  [A.removeDispatchIntegrationConfigSuccess]: removeDispatchIntegrationConfigSuccess,
  [A.createOrUpdateTollChargesConfigSuccess]: createOrUpdateTollChargesConfigSuccess,
  [A.receivedAccessorialServiceCodesSuccess]: receivedAccessorialServiceCodesSuccess,
  [getGeneralConfigsForTelConfigSuccess]: handleGetGeneralConfigsForTelConfigSuccess,
  [A.receivedStatusMessageListForCloSuccess]: receivedStatusMessageListForCloSuccess,
  [A.getDistanceCalculatorConfigListSuccess]: getDistanceCalculatorConfigListSuccess,
  [A.removeLoadBoardIntegrationConfigSuccess]: removeLoadBoardIntegrationConfigSuccess,
  [A.getDispatchIntegrationConfigListSuccess]: getDispatchIntegrationConfigListSuccess,
  [A.getStatusMessagesForGeneralConfigSuccess]: getStatusMessagesForGeneralConfigSuccess,
  [A.getReferenceTypesByScopeForConfigSuccess]: getReferenceTypesByScopeForConfigSuccess,
  [A.getLoadBoardIntegrationConfigListSuccess]: getLoadBoardIntegrationConfigListSuccess,
  [A.createOrUpdateGpsIntegrationConfigSuccess]: createOrUpdateGpsIntegrationConfigSuccess,
  [A.receivedCarrierRateIntegrationListSuccess]: receivedCarrierRateIntegrationListSuccess,
  [A.getAccessorialConfigListForTelConfigSuccess]: getAccessorialConfigListForTelConfigSuccess,
  [A.getAccessorialConfigListForCloConfigSuccess]: getAccessorialConfigListForCloConfigSuccess,
  [A.createOrUpdateCarrierRateIntegrationSuccess]: createOrUpdateCarrierRateIntegrationSuccess,
  [A.receivedCarrierOnboardingDocumentListSuccess]: receivedCarrierOnboardingDocumentListSuccess,
  [A.createOrUpdateCarrierOnboardingPackageSuccess]: createOrUpdateCarrierOnboardingPackageSuccess,
  [A.getBranchConfigsByNamesForCarrierGroupSuccess]: getBranchConfigsByNamesForCarrierGroupSuccess,
  [A.deleteCarrierOnboardingPackageDocumentSuccess]: deleteCarrierOnboardingPackageDocumentSuccess,
  [A.createOrUpdateDispatchIntegrationConfigSuccess]: createOrUpdateDispatchIntegrationConfigSuccess,
  [A.createOrUpdateFleetServiceInvoiceStatusSuccess]: createOrUpdateFleetServiceInvoiceStatusSuccess,
  [A.createOrUpdateLoadBoardIntegrationConfigSuccess]: createOrUpdateLoadBoardIntegrationConfigSuccess,
  [A.createOrUpdateCarrierOnboardingPackageDocumentSuccess]: createOrUpdateCarrierOnboardingPackageDocumentSuccess,
  // status code mapping
  [A.removeStatusCodeMappingSuccess]: removeStatusCodeMappingSuccess,
  [A.getStatusCodeMappingListSuccess]: getStatusCodeMappingListSuccess,
  [A.createOrUpdateStatusCodeMappingSuccess]: createOrUpdateStatusCodeMappingSuccess,
  // status code mapping
  [A.removeStatusReasonCodeMappingSuccess]: removeStatusReasonCodeMappingSuccess,
  [A.getStatusReasonCodeMappingListSuccess]: getStatusReasonCodeMappingListSuccess,
  [A.createOrUpdateStatusReasonCodeMappingSuccess]: createOrUpdateStatusReasonCodeMappingSuccess,
  // qb account mapping
  [A.setQBAccountMappingFilter]: setQBAccountMappingFilter,
  [A.removeQBAccountMappingSuccess]: removeQBAccountMappingSuccess,
  [A.getQBAccountMappingListSuccess]: getQBAccountMappingListSuccess,
  [A.createOrUpdateQBAccountMappingSuccess]: createOrUpdateQBAccountMappingSuccess,
  // sage account mapping
  [A.setSageAccountMappingFilter]: setSageAccountMappingFilter,
  [A.removeSageAccountMappingSuccess]: removeSageAccountMappingSuccess,
  [A.getSageAccountMappingListSuccess]: getSageAccountMappingListSuccess,
  [A.createOrUpdateSageAccountMappingSuccess]: createOrUpdateSageAccountMappingSuccess,
  // customer id mapping
  [A.removeCustomerIdMappingSuccess]: removeCustomerIdMappingSuccess,
  [A.getCustomerIdMappingListSuccess]: getCustomerIdMappingListSuccess,
  [A.createOrUpdateCustomerIdMappingSuccess]: createOrUpdateCustomerIdMappingSuccess,
  // department id mapping
  [A.removeDepartmentIdMappingSuccess]: removeDepartmentIdMappingSuccess,
  [A.getDepartmentIdMappingListSuccess]: getDepartmentIdMappingListSuccess,
  [A.createOrUpdateDepartmentIdMappingSuccess]: createOrUpdateDepartmentIdMappingSuccess,
  // public clo
  [A.getPublicCLOConfigSuccess]: getPublicCLOConfigSuccess,
  [A.removePublicCLOConfigSuccess]: removePublicCLOConfigSuccess,
  // service type mapping
  [A.removeServiceTypeMappingSuccess]: removeServiceTypeMappingSuccess,
  [A.getServiceTypeMappingListSuccess]: getServiceTypeMappingListSuccess,
  [A.createOrUpdateServiceTypeMappingSuccess]: createOrUpdateServiceTypeMappingSuccess,
  // carrier synchronization
  [A.getCarrierSynchronizationConfigSuccess]: getCarrierSynchronizationConfigSuccess,
  [A.removeCarrierSynchronizationConfigSuccess]: removeCarrierSynchronizationConfigSuccess,
  // default order accessorials
  [A.removeDefaultOrderAccessorialSuccess]: removeDefaultOrderAccessorialSuccess,
  [A.getDefaultOrderAccessorialListSuccess]: getDefaultOrderAccessorialListSuccess,
  [A.createOrUpdateDefaultOrderAccessorialSuccess]: createOrUpdateDefaultOrderAccessorialSuccess,
  // default carrier accessorials
  [A.removeDefaultCarrierAccessorialSuccess]: removeDefaultCarrierAccessorialSuccess,
  [A.getDefaultCarrierAccessorialListSuccess]: getDefaultCarrierAccessorialListSuccess,
  [A.createOrUpdateDefaultCarrierAccessorialSuccess]: createOrUpdateDefaultCarrierAccessorialSuccess,
  // user monitoring
  [A.removeUserMonitoringIntegrationSuccess]: removeUserMonitoringIntegrationSuccess,
  [A.receivedUserMonitoringIntegrationListSuccess]: receivedUserMonitoringIntegrationListSuccess,
  [A.createOrUpdateUserMonitoringIntegrationSuccess]: createOrUpdateUserMonitoringIntegrationSuccess,
  // carrier integration override
  [A.removeCarrierIntegrationOverrideSuccess]: removeCarrierIntegrationOverrideSuccess,
  [A.getCarrierIntegrationOverrideListSuccess]: getCarrierIntegrationOverrideListSuccess,
  [A.createOrUpdateCarrierIntegrationOverrideSuccess]: createOrUpdateCarrierIntegrationOverrideSuccess,
  // gl code mapping
  [A.setGLCodeMappingFilter]: setGLCodeMappingFilter,
  [A.removeGLCodeMappingSuccess]: removeGLCodeMappingSuccess,
  [A.getGLCodeMappingListSuccess]: getGLCodeMappingListSuccess,
  [A.createOrUpdateCLCodeMappingSuccess]: createOrUpdateCLCodeMappingSuccess,
  // integration mail config
  [A.getIntegrationMailConfigSuccess]: getIntegrationMailConfigSuccess,
  // mail template config
  [A.getMailTemplateTypesSuccess]: getMailTemplateTypesSuccess,
  [A.getMailTemplateByKeySuccess]: getMailTemplateByKeySuccess,
  [A.getMailTemplateConfigSuccess]: getMailTemplateConfigSuccess,
  [A.getMailTemplateNamesByTypeSuccess]: getMailTemplateNamesByTypeSuccess,
  // document type mapping
  [A.removeDocumentTypeMappingSuccess]: removeDocumentTypeMappingSuccess,
  [A.getDocumentTypeMappingListSuccess]: getDocumentTypeMappingListSuccess,
  [A.createOrUpdateDocumentTypeMappingSuccess]: createOrUpdateDocumentTypeMappingSuccess,
  // document hub config
  [A.removeDocumentHubConfigSuccess]: removeDocumentHubConfigSuccess,
  [A.getDocumentHubConfigListSuccess]: getDocumentHubConfigListSuccess,
  [A.createOrUpdateDocumentHubConfigSuccess]: createOrUpdateDocumentHubConfigSuccess,
  // accounting
  [A.removeAccountingConfigSuccess]: removeAccountingConfigSuccess,
  [A.getAccountingConfigListSuccess]: getAccountingConfigListSuccess,
  [A.createOrUpdateAccountingConfigSuccess]: createOrUpdateAccountingConfigSuccess,
  // carrier pickup requester
  [A.removeCarrierPickupRequesterSuccess]: removeCarrierPickupRequesterSuccess,
  [A.getCarrierPickupRequesterListSuccess]: getCarrierPickupRequesterListSuccess,
  [A.createOrUpdateCarrierPickupRequesterSuccess]: createOrUpdateCarrierPickupRequesterSuccess,
  [A.getAvailableCarrierPickupRequesterListSuccess]: getAvailableCarrierPickupRequesterListSuccess,
  // invoice status rule
  [A.setInvoiceStatusRuleFilter]: setInvoiceStatusRuleFilter,
  [A.getInvoiceStatusRuleListSuccess]: getInvoiceStatusRuleListSuccess,
  // carrier edi loader config
  [A.getCarrierOptionsSuccess]: getCarrierOptionsSuccess,
  [A.removeCarrierEdiLoaderConfigSuccess]: removeCarrierEdiLoaderConfigSuccess,
  [A.getCarrierEdiLoaderConfigListSuccess]: getCarrierEdiLoaderConfigListSuccess,
  [A.createOrUpdateCarrierEdiLoaderConfigSuccess]: createOrUpdateCarrierEdiLoaderConfigSuccess,
  // carrier edi exporter config
  [A.removeCarrierEdiExporterConfigSuccess]: removeCarrierEdiExporterConfigSuccess,
  [A.getCarrierEdiExporterConfigListSuccess]: getCarrierEdiExporterConfigListSuccess,
  [A.createOrUpdateCarrierEdiExporterConfigSuccess]: createOrUpdateCarrierEdiExporterConfigSuccess,
  // transportation mode config mapping
  [A.removeTransportationModeConfigMappingSuccess]: removeTransportationModeConfigMappingSuccess,
  [A.getTransportationModeConfigMappingListSuccess]: getTransportationModeConfigMappingListSuccess,
  [A.createOrUpdateTransportationModeConfigMappingSuccess]: createOrUpdateTransportationModeConfigMappingSuccess,
  // vin lookup config
  [A.removeVinLookupConfigSuccess]: removeVinLookupConfigSuccess,
  [A.getVinLookupConfigListSuccess]: getVinLookupConfigListSuccess,
  [A.createOrUpdateVinLookupConfigSuccess]: createOrUpdateVinLookupConfigSuccess,
  // load custom status config
  [A.removeLoadCustomStatusSuccess]: removeLoadCustomStatusSuccess,
  [A.getLoadCustomStatusListSuccess]: getLoadCustomStatusListSuccess,
  [A.createOrUpdateLoadCustomStatusSuccess]: createOrUpdateLoadCustomStatusSuccess,
  // factoring integration config
  [A.removeFactoringIntegrationConfigSuccess]: removeFactoringIntegrationConfigSuccess,
  [A.getFactoringIntegrationConfigListSuccess]: getFactoringIntegrationConfigListSuccess,
  [A.createOrUpdateFactoringIntegrationConfigSuccess]: createOrUpdateFactoringIntegrationConfigSuccess,
  // equipment mapping config
  [A.removeEquipmentMappingConfigSuccess]: removeEquipmentMappingConfigSuccess,
  [A.getEquipmentMappingConfigListSuccess]: getEquipmentMappingConfigListSuccess,
  [A.createOrUpdateEquipmentMappingConfigSuccess]: createOrUpdateEquipmentMappingConfigSuccess,
  // available edi export mappers
  [A.getAvailableEdiExportMappersSuccess]: getAvailableEdiExportMappersSuccess,
  // margin violation rule
  [A.removeMarginViolationRuleSuccess]: removeMarginViolationRuleSuccess,
  [A.getMarginViolationRuleListSuccess]: getMarginViolationRuleListSuccess,
  [A.createOrUpdateMarginViolationRuleSuccess]: createOrUpdateMarginViolationRuleSuccess,
  // trailer tracking integration config
  [A.removeTrailerTrackingIntegrationConfigSuccess]: removeTrailerTrackingIntegrationConfigSuccess,
  [A.getTrailerTrackingIntegrationConfigListSuccess]: getTrailerTrackingIntegrationConfigListSuccess,
  [A.createOrUpdateTrailerTrackingIntegrationConfigSuccess]: createOrUpdateTrailerTrackingIntegrationConfigSuccess,
  // onboarding integration config
  [A.getOnboardingIntegrationConfigListSuccess]: getOnboardingIntegrationConfigListSuccess,
  // los subscription config
  [A.unlinkLosAccountSuccess]: unlinkLosAccountSuccess,
  [A.getLosSubscriptionConfigSuccess]: getLosSubscriptionConfigSuccess,
  [A.updateLosSubscriptionConfigSuccess]: updateLosSubscriptionConfigSuccess,
  // los pricing plan
  [A.removeLosPricingPlanConfigSuccess]: removeLosPricingPlanConfigSuccess,
  [A.getLosPricingPlanConfigListSuccess]: getLosPricingPlanConfigListSuccess,
  [A.createOrUpdateLosPricingPlanConfigSuccess]: createOrUpdateLosPricingPlanConfigSuccess,
  // carrier scorecard config
  [A.removeCarrierScorecardConfigSuccess]: removeCarrierScorecardConfigSuccess,
  [A.getCarrierScorecardConfigListSuccess]: getCarrierScorecardConfigListSuccess,
  [A.createOrUpdateCarrierScoreCardconfigSuccess]: createOrUpdateCarrierScoreCardconfigSuccess,
  // terminal integration config
  [A.removeTerminalIntegrationConfigSuccess]: removeTerminalIntegrationConfigSuccess,
  [A.getTerminalIntegrationConfigListSuccess]: getTerminalIntegrationConfigListSuccess,
  [A.createOrUpdateTerminalIntegrationConfigSuccess]: createOrUpdateTerminalIntegrationConfigSuccess,
  // task type
  [A.removeTaskTypeSuccess]: removeTaskTypeSuccess,
  [A.getTaskTypeListSuccess]: getTaskTypeListSuccess,
  [A.createOrUpdateTaskTypeSuccess]: createOrUpdateTaskTypeSuccess,
  // task status
  [A.removeTaskStatusSuccess]: removeTaskStatusSuccess,
  [A.getTaskStatusListSuccess]: getTaskStatusListSuccess,
  [A.createOrUpdateTaskStatusSuccess]: createOrUpdateTaskStatusSuccess,
  // transportation mode grouping
  [A.removeTransportationModeGroupingSuccess]: removeTransportationModeGroupingSuccess,
  [A.getTransportationModeGroupingListSuccess]: getTransportationModeGroupingListSuccess,
  [A.createOrUpdateTransportationModeGroupingSuccess]: createOrUpdateTransportationModeGroupingSuccess,
  // document sending config
  [A.removeDocumentSendingConfigSuccess]: removeDocumentSendingConfigSuccess,
  [A.getDocumentSendingConfigListSuccess]: getDocumentSendingConfigListSuccess,
  [A.createOrUpdateDocumentSendingConfigSuccess]: createOrUpdateDocumentSendingConfigSuccess,
  // expense types
  [A.removeExpenseTypeSuccess]: removeExpenseTypeSuccess,
  [A.getExpenseTypeListSuccess]: getExpenseTypeListSuccess,
  [A.createOrUpdateExpenseTypeSuccess]: createOrUpdateExpenseTypeSuccess,
  // cross border integration config
  [A.getCrossBorderIntegrationConfigSuccess]: getCrossBorderIntegrationConfigSuccess,
  [A.removeCrossBorderIntegrationConfigSuccess]: removeCrossBorderIntegrationConfigSuccess,
  [A.createOrUpdateCrossBorderIntegrationConfigSuccess]: createOrUpdateCrossBorderIntegrationConfigSuccess,
  // truck type mapping
  [A.removeTruckTypeMappingSuccess]: removeTruckTypeMappingSuccess,
  [A.getTruckTypeMappingListSuccess]: getTruckTypeMappingListSuccess,
  [A.createOrUpdateTruckTypeMappingSuccess]: createOrUpdateTruckTypeMappingSuccess,
  // trailer type mapping
  [A.removeTrailerTypeMappingSuccess]: removeTrailerTypeMappingSuccess,
  [A.getTrailerTypeMappingListSuccess]: getTrailerTypeMappingListSuccess,
  [A.createOrUpdateTrailerTypeMappingSuccess]: createOrUpdateTrailerTypeMappingSuccess,
  // tabs
  [A.setIntegrationActiveConfigTab]: setActiveConfigTab,
}, initialState);
