// features
import PC from '../../permission/role-permission';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
//////////////////////////////////////////////////

/*
  If you need to open any configuration page with default tab pre-opened
  add config group to defaultPageConfigEnhancer and useTabs to index config page
  add tabId to any tab list (configuration page tabs) and to constants/tabs
  and trigger route with search parameter ?tab=...
  use 'push' from 'react-router-redux';
  push('some uri with search param');
*/

const integrationTabs = [
  {
    tabId: GC.TAB_INTEGRATION_FINANCIAL,
    text: G.getWindowLocale('titles:financial', 'Financial'),
  },
  {
    tabId: GC.TAB_INTEGRATION_CUSTOMER,
    text: G.getWindowLocale('titles:customer', 'Customer'),
  },
  {
    tabId: GC.TAB_INTEGRATION_FLEET_VENDOR,
    text: G.getWindowLocale('titles:fleet-vendor', 'Fleet Vendor'),
  },
  {
    tabId: GC.TAB_INTEGRATION_CARRIER_VENDOR,
    text: G.getWindowLocale('titles:carrier-vendor', 'Carrier Vendor'),
  },
  {
    tabId: GC.TAB_INTEGRATION_LOAD_BOARDS,
    permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
    text: G.getWindowLocale('titles:load-boards', 'Load Boards'),
  },
  {
    tabId: GC.TAB_INTEGRATION_ANALYTICS,
    text: G.getWindowLocale('titles:analytics', 'Analytics'),
  },
  {
    tabId: GC.TAB_INTEGRATION_DOCUMENTS,
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
  {
    tabId: GC.TAB_INTEGRATION_ACCOUNTING,
    text: G.getWindowLocale('titles:accounting', 'Accounting'),
  },
  {
    tabId: GC.TAB_INTEGRATION_VEHICLE,
    text: G.getWindowLocale('titles:vehicle', 'Vehicle'),
  },
  {
    tabId: GC.TAB_INTEGRATION_STATUS_CODE_MAPPING,
    text: G.getWindowLocale('titles:status-code-mapping', 'Status Code Mapping'),
  },
  {
    tabId: GC.TAB_INTEGRATION_BOSCH,
    text: G.getWindowLocale('titles:bosch-los', 'Bosch LOS'),
  },
  {
    tabId: GC.TAB_INTEGRATION_TRAILER,
    text: G.getWindowLocale('titles:trailer', 'Trailer'),
  },
  {
    tabId: GC.TAB_INTEGRATION_WMS,
    text: G.getWindowLocale('titles:wms', 'WMS'),
  },
  {
    tabId: GC.TAB_INTEGRATION_CROSS_BORDER,
    text: G.getWindowLocale('titles:cross-border', 'Cross Border'),
  },
];

const invoiceTabs = [
  {
    tabId: GC.TAB_INVOICES_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_INVOICES_QUICK_BOOKS,
    text: G.getWindowLocale('titles:quick-books', 'QuickBooks'),
  },
  {
    tabId: GC.TAB_INVOICES_SAGE_INTACCT,
    text: G.getWindowLocale('titles:sage-intacct', 'Sage Intacct'),
  },
  {
    tabId: GC.TAB_INVOICES_BUSINESS_CENTAL,
    text: G.getWindowLocale('titles:business-central', 'Business Cental'),
  },
  {
    tabId: GC.TAB_INVOICES_FLEET_INVOICE,
    text: G.getWindowLocale('titles:fleet-invoices', 'Fleet Invoice(s)'),
  },
  {
    tabId: GC.TAB_INVOICES_CUSTOMER_INVOICE,
    text: G.getWindowLocale('titles:customer-invoices', 'Customer Invoice(s)'),
  },
  {
    tabId: GC.TAB_INVOICES_CARRIER_INVOICE,
    text: G.getWindowLocale('titles:carrier-invoices', 'Carrier Invoice(s)'),
  },
  {
    tabId: GC.TAB_INVOICES_GL_CODES,
    permissions: [PC.GL_CODE_WRITE],
    text: G.getWindowLocale('titles:gl-codes', 'GL Codes'),
  },
  {
    tabId: GC.TAB_INVOICES_EXPENSE_TYPES,
    text: G.getWindowLocale('titles:expenses', 'Expenses'),
  },
];

const generalTabs = [
  {
    tabId: GC.TAB_GENERAL_BRANCH,
    text: G.getWindowLocale('titles:branch', 'Branch'),
  },
  {
    tabId: GC.TAB_GENERAL_ROUTE,
    text: G.getWindowLocale('titles:route', 'Route'),
  },
  {
    tabId: GC.TAB_GENERAL_LOAD_CUSTOM_STATUS,
    text: G.getWindowLocale('titles:load-custom-status', 'Load Custom Status'),
  },
  {
    tabId: GC.TAB_GENERAL_SERVICES,
    text: G.getWindowLocale('titles:services', 'Services'),
  },
  {
    tabId: GC.TAB_GENERAL_ACCESSORIALS,
    text: G.getWindowLocale('titles:accessorials', 'Accessorials'),
  },
  {
    tabId: GC.TAB_GENERAL_EQUIPMENTS,
    text: G.getWindowLocale('titles:equipments', 'Equipments'),
  },
  {
    tabId: GC.TAB_GENERAL_MODE_OF_TRANSPORTATION,
    text: G.getWindowLocale('titles:mode-of-transportation', 'Mode of Transportation'),
  },
  {
    tabId: GC.TAB_GENERAL_SERVICE_TYPE,
    text: G.getWindowLocale('titles:service-type', 'Service Type'),
  },
  {
    tabId: GC.TAB_GENERAL_CALCULATIONS,
    text: G.getWindowLocale('titles:calculations', 'Calculations'), // TODO: clarify and add to locale
  },
];

const driverTabs = [
  {
    tabId: GC.TAB_FLEET_DRIVER_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_FLEET_DRIVER_TRACKING,
    text: G.getWindowLocale('titles:tracking', 'Tracking'),
  },
  {
    tabId: GC.TAB_FLEET_DRIVER_SAFETY,
    text: G.getWindowLocale('titles:safety', 'Safety'),
  },
  {
    tabId: GC.TAB_FLEET_DRIVER_ONBOARDING_INTEGRATION,
    text: G.getWindowLocale('titles:onboarding-integration', 'Onboarding Integration'),
  },
];

const uiTabs = [
  {
    tabId: GC.TAB_UI_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_UI_CLO,
    text: G.getWindowLocale('titles:order', 'Order'),
  },
  {
    tabId: GC.TAB_UI_TEL,
    text: G.getWindowLocale('titles:tel', 'Trip'),
  },
  {
    tabId: GC.TAB_UI_DISPATCH_BOARD,
    text: G.getWindowLocale('titles:dispatch-board', 'Dispatch Board'),
  },
  {
    tabId: GC.TAB_UI_DRIVER_CARDS,
    text: G.getWindowLocale('titles:driver-cards', 'Driver Cards'),
  },
  {
    tabId: GC.TAB_UI_AVAILABLE_DRIVERS,
    text: G.getWindowLocale('titles:available-drivers', 'Available Drivers'),
  },
  {
    tabId: GC.TAB_UI_LOAD_BOARDS,
    permissions: [PC.EXTERNAL_LOAD_BOARD_EXECUTE],
    text: G.getWindowLocale('titles:load-boards', 'Load Boards'),
  },
];

const orderTabs = [
  {
    tabId: GC.TAB_CLO_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_CLO_ORDER_TYPE,
    text: G.getWindowLocale('titles:order-type', 'Order Type'),
  },
  {
    tabId: GC.TAB_CLO_BILL_TO,
    text: G.getWindowLocale('titles:bill-to', 'Bill To'),
  },
  {
    tabId: GC.TAB_CLO_ITEM,
    text: G.getWindowLocale('titles:item', 'Item'),
  },
  {
    tabId: GC.TAB_CLO_RATE,
    text: G.getWindowLocale('titles:rate', 'Rate'),
  },
  {
    tabId: GC.TAB_CLO_CANCEL_ORDER,
    text: G.getWindowLocale('titles:cancel-order', 'Cancel Order'),
  },
  {
    tabId: GC.TAB_CLO_QUOTE,
    text: G.getWindowLocale('titles:quote', 'Quote'),
  },
];

const carrierTabs = [
  {
    tabId: GC.TAB_CARRIER_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_CARRIER_STATUS_CHECK,
    text: G.getWindowLocale('titles:status-check', 'Status Check'),
  },
  {
    tabId: GC.TAB_CARRIER_DISPATCH_PROCESS,
    text: G.getWindowLocale('titles:dispatch-process', 'Dispatch Process'),
  },
  {
    tabId: GC.TAB_CARRIER_QUOTE_PROCESS,
    text: G.getWindowLocale('titles:quote-process', 'Quote Process'),
  },
  {
    tabId: GC.TAB_CARRIER_ACCEPT_RATE,
    text: G.getWindowLocale('titles:accept-rate', 'Accept Rate'),
  },
  {
    tabId: GC.TAB_CARRIER_INSURANCE,
    text: G.getWindowLocale('titles:insurance', 'Insurance'),
  },
  {
    tabId: GC.TAB_CARRIER_ONBOARDING_PACKAGE,
    text: G.getWindowLocale('titles:onboarding-package', 'Onboarding Package'),
  },
  {
    tabId: GC.TAB_CARRIER_DROPDOWNS,
    text: G.getWindowLocale('titles:dropdowns', 'Dropdowns'),
  },
  {
    tabId: GC.TAB_CARRIER_SCORE_CARDS,
    text: G.getWindowLocale('titles:score-cards', 'Score Cards'),
  },
];

const telTabs = [
  {
    tabId: GC.TAB_TEL_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_TEL_RATE,
    text: G.getWindowLocale('titles:rate', 'Rate'),
  },
];

const mobileAppTabs = [
  {
    tabId: GC.TAB_MOBILE_APPLICATION_DRIVER,
    text: G.getWindowLocale('titles:driver', 'Driver'),
  },
  {
    tabId: GC.TAB_MOBILE_APPLICATION_CARRIER,
    name: GC.MOBILEAPP_CONFIG_TAB_NAME_CARRIER,
    text: G.getWindowLocale('titles:carrier', 'Carrier'),
  },
];

const communicationTabs = [
  {
    tabId: GC.TAB_COMMUNICATION_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_COMMUNICATION_NOTIFICATIONS,
    text: G.getWindowLocale('titles:notifications', 'Notifications'),
  },
  {
    tabId: GC.TAB_COMMUNICATION_STATUS_MESSAGES,
    text: G.getWindowLocale('titles:status-messages', 'Status Messages'),
  },
  {
    tabId: GC.TAB_COMMUNICATION_MAILS,
    text: G.getWindowLocale('titles:mails', 'Mails'),
  },
  {
    tabId: GC.TAB_COMMUNICATION_DOCUMENTS,
    text: G.getWindowLocale('titles:documents', 'Documents'),
  },
];

const serviceVendorTabs = [
  {
    tabId: GC.TAB_SERVICE_VENDOR_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
];

const fleetTruckTabs = [
  {
    tabId: GC.TAB_FLEET_TRUCK_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_FLEET_TRUCK_MAINTENANCE,
    text: G.getWindowLocale('titles:maintenance', 'Maintenance'),
  },
  {
    tabId: GC.TAB_FLEET_TRUCK_IFTA,
    text: G.getWindowLocale('titles:ifta', 'IFTA'),
  },
];

const fleetTrailerTabs = [
  {
    tabId: GC.TAB_FLEET_TRAILER_GENERAL,
    text: G.getWindowLocale('titles:general', 'General'),
  },
  {
    tabId: GC.TAB_FLEET_TRAILER_MAINTENANCE,
    text: G.getWindowLocale('titles:maintenance', 'Maintenance'),
  },
];

export default {
  [GC.UI_CONFIG_GROUP]: uiTabs,
  [GC.TEL_CONFIG_GROUP]: telTabs,
  [GC.CLO_CONFIG_GROUP]: orderTabs,
  [GC.DRIVER_CONFIG_GROUP]: driverTabs,
  [GC.GENERAL_CONFIG_GROUP]: generalTabs,
  [GC.INVOICE_CONFIG_GROUP]: invoiceTabs,
  [GC.CARRIER_CONFIG_GROUP]: carrierTabs,
  [GC.TRUCK_CONFIG_GROUP]: fleetTruckTabs,
  [GC.MOBILEAPP_CONFIG_GROUP]: mobileAppTabs,
  [GC.TRAILER_CONFIG_GROUP]: fleetTrailerTabs,
  [GC.INTEGRATION_CONFIG_GROUP]: integrationTabs,
  [GC.COMMUNICATION_CONFIG_GROUP]: communicationTabs,
  [GC.SERVICE_VENDOR_CONFIG_GROUP]: serviceVendorTabs,
};

export {
  uiTabs,
  telTabs,
  orderTabs,
  driverTabs,
  invoiceTabs,
  generalTabs,
  carrierTabs,
  mobileAppTabs,
  fleetTruckTabs,
  integrationTabs,
  fleetTrailerTabs,
  serviceVendorTabs,
  communicationTabs,
};
