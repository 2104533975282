import * as R from 'ramda';
import { put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../components/loader/actions';
import {
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../../../components/edit-report/helpers';
// features
import { getConfigByGroupSaga } from '../../configurations/sagas';
import { makeSelectCurrentBranchGuid } from '../../branch/selectors';
import { getAccessorialForConfigRequest } from '../../configurations/actions';
import { getAllAvailableRefTypesByScopeRequest } from '../../reference/actions';
import { getAvailableReportGeoFencingZoneListRequest } from '../../geo-fencing-zone/report/actions';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// report-common
import { getReportSagas } from '../../../report-common';
// sagas
import { visitPageSaga } from '../../../sagas';
// utilities
import { sendRequest } from '../../../utilities/http';
import endpointsMap from '../../../utilities/endpoints';
// feature dispatch-report/order
import * as A from './actions';
import { RC } from '../settings/filter-params';
import {
  makeSelectUsedReport,
  makeSelectPagination,
  makeSelectPageVisited,
  makeSelectFilterParams,
  makeSelectTitleSortValues,
  makeSelectAvailableReports,
  makeSelectTableTitleFilters,
} from './selectors';
//////////////////////////////////////////////////

function* handleGetItemListSaga({ payload }: boolean) {
  try {
    if (G.isTrue(G.getPropFromObject('openLoader', payload))) {
      yield put(openLoader({ showDimmer: true }));
    }

    yield put(A.setListLoading(true));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('cloDefault', reportParams.guid),
    )) {
      yield put(A.setListLoading(false));

      return yield put(closeLoader());
    }

    const pagination = yield select(makeSelectPagination());
    const filterParams = yield select(makeSelectFilterParams());
    const titleOrderFields = yield select(makeSelectTitleSortValues());
    const titleFilterParams = yield select(makeSelectTableTitleFilters());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

    const orderFields = G.ifElse(
      G.isNotEmpty(titleOrderFields),
      R.values(titleOrderFields),
      G.getOrElse(reportParams, 'orderFields', []),
    );

    const searchCriteria = G.ifElse(
      G.isNotEmpty(titleFilterParams),
      R.values(titleFilterParams),
      G.getOrElse(reportParams, 'searchCriteria', []),
    );

    let fields = G.getOrElse(reportParams, 'fields', []);

    let requiredFields = [
      RC.TELS_GUID,
      RC.CLAIM_GUID,
      GC.BRANCH_GUID,
    ];

    if (G.isTrue(reportParams.showIssues)) {
      requiredFields = R.append(GC.FIELD_LOAD_APPOINTMENT_REQUIRED, requiredFields);
    }

    fields = G.addRequiredFields(fields, requiredFields);

    const guids = R.pathOr(null, ['guids'], payload);

    const reqBody = {
      guids,
      fields,
      orderFields,
      limit: pagination.limit,
      offset: pagination.offset,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      searchCriteria: transformSearchCriteriaBeforeReportPost(searchCriteria),
    };

    const reqData = G.setSearchCriteria({ reqBody, filterParams: newFilterParams });

    const res = yield call(sendRequest, 'post', endpointsMap.cloList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getItemListSuccess({ data, guids }));
    } else {
      yield call(G.handleFailResponse, res, 'handleGetItemListSaga fail');
    }

    yield put(A.setListLoading(false));
    yield put(closeLoader());
  } catch (err) {
    yield put(A.setListLoading(false));
    yield put(closeLoader());

    yield call(G.handleException, err, 'handleGetItemListSaga exception');
  }
}

function* handleExportReportDataSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const filterParams = yield select(makeSelectFilterParams());
    const newFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const searchCriteria = transformSearchCriteriaBeforeReportPost(payload.searchCriteria);

    const reqBody = {
      searchCriteria,
      fields: payload.fields,
      orderFields: payload.orderFields,
      showAllEvents: payload.showAllEvents,
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      [GC.FIELD_REPORT_NAME]: G.getPropFromObject(GC.FIELD_NAME, payload),
    };

    const options = {
      resType: 'arraybuffer',
      params: { format: payload.fileType },
      data: G.setSearchCriteria({ filterParams: newFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloExportReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handleExportReportDataSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleExportReportDataSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleGetXMLSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('cloDefault', reportParams.guid),
    )) {
      return yield put(closeLoader());
    }

    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.getCloListXML, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      G.downloadFile(data, 'clolist.xml');

      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleGetXMLSaga fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleGetXMLSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handlePrintSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const availableReports = yield select(makeSelectAvailableReports());
    const reportParams = yield select(makeSelectUsedReport());

    if (R.and(
      G.isNilOrEmpty(availableReports),
      R.equals('cloDefault', reportParams.guid),
    )) {
      return yield put(closeLoader());
    }

    const filterParams = yield select(makeSelectFilterParams());
    const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      fields: G.getOrElse(reportParams, 'fields', []),
      orderFields: G.getOrElse(reportParams, 'orderFields', []),
      searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
    };

    const options = {
      params: payload,
      resType: 'arraybuffer',
      data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
    };

    const res = yield call(sendRequest, 'post', endpointsMap.cloDocumentPrintByReport, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());

      yield call(G.showToastrMessage, 'info', 'messages:downloading-file');
    } else {
      yield call(G.handleFailResponse, res, 'handlePrintSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handlePrintSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleDeleteItemSaga({ payload }: Object) {
  try {
    yield put(openLoader({ showDimmer: true }));

    const res = yield call(sendRequest, 'delete', endpointsMap.getCloEntity(payload));

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'toastr:success:204');

      yield put(A.deleteItemSuccess(payload));
      yield put(closeModal());
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteItemSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleDeleteItemSaga exception');
  }
}

function* handleCreateReferenceSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const res = yield call(sendRequest, 'post', endpointsMap.cloMassReference, { data: payload });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.createReferenceSuccess(R.head(data)));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateReferenceSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleCreateReferenceSaga exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* handleChangeCancelStatusRequestSaga({ payload }: Object) {
  try {
    yield put(openLoader());

    const guid = R.path(['data', GC.FIELD_GUID], payload);

    let options;

    if (G.isNotNilAndNotEmpty(R.path([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload))) {
      options = {
        params: R.pick([GC.FIELD_LOAD_CANCEL_REASON_GUID], payload),
      };
    }

    const endpoint = {
      cancel: endpointsMap.cloCancel(guid),
      restore: endpointsMap.cloRestore(guid),
    };

    const res = yield call(sendRequest, 'put', endpoint[payload.type], options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'handleChangeCancelStatusRequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* cancelCLORequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const options = { data: payload };

    const res = yield call(sendRequest, 'put', endpointsMap.cancelCLO, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.resetListAndPagination());
      yield put(A.getItemListRequest(true));
    } else {
      yield call(G.handleFailResponse, res, 'cancelCLORequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'cancelCLORequest exception');
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
  }
}

function* getConfigsByNamesSaga({ payload }: Object) {
  try {
    const { names, branchGuid } = payload;

    const options = {
      params: { names, [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpointsMap.branchConfigsEndpoint, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      const mappedConfigs = G.mapConfigValuesByName(data);

      yield put(A.getConfigsByNamesSuccess(mappedConfigs));
    } else {
      yield call(G.handleFailResponse, res, 'getConfigsByNamesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsByNamesSaga exception');
  }
}

const createUpdateReportSuccessCallback = (data: Object) => G.getReportSortedBySeqFreez(data);

const {
  handleAvailableReportsRequest,
  handleCreateReportRequestSaga,
  handleUpdateReportRequestSaga,
  handleChangeDefaultReportSaga,
} = getReportSagas(GC.CLO_REPORT, A, handleGetItemListSaga, { createUpdateReportSuccessCallback });

const CONFIGS_ARR = [GC.CLO_GENERAL_LOAD_CANCEL_REASON];

function* handleVisitOrderListPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield put(openLoader({ showDimmer: true }));

    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CLO_LIST_PAGE);

    const pageVisited = yield select(makeSelectPageVisited());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    yield put(A.getConfigsByNamesRequest({
      branchGuid,
      names: R.join(',', CONFIGS_ARR),
    }));

    if (G.isTrue(pageVisited)) {
      yield put(A.setInitialStateOmitReport());
    } else {
      yield put(A.setInitialState());
    }

    yield call(getConfigByGroupSaga, GC.GENERAL_CONFIG_GROUP);

    yield put(getAccessorialForConfigRequest());
    yield put(A.setIgnorePromptStatus(false));
    yield put(getAllAvailableRefTypesByScopeRequest(GC.REF_SCOPE_NAME_CLO));
    yield put(getAvailableReportGeoFencingZoneListRequest());
    yield put(A.setReportPending());

    yield call(handleAvailableReportsRequest, { payload, notSetUsedReport: pageVisited });
    yield call(handleGetItemListSaga, { payload: { openLoader: true } });

    yield put(closeLoader());

    break;
  }
}

function* orderListWatcherSaga() {
  yield takeLatest(A.printRequest, handlePrintSaga);
  yield takeLatest(A.getXMLRequest, handleGetXMLSaga);
  yield takeLatest(A.cancelCLORequest, cancelCLORequest);
  yield takeLatest(A.deleteItemRequest, handleDeleteItemSaga);
  yield takeLatest(A.getItemListRequest, handleGetItemListSaga);
  yield takeLatest(A.getConfigsByNamesRequest, getConfigsByNamesSaga);
  yield takeLatest(A.createReferenceRequest, handleCreateReferenceSaga);
  yield takeLatest(GC.VISIT_CLO_LIST_PAGE, handleVisitOrderListPageSaga);
  yield takeLatest(A.createReportRequest, handleCreateReportRequestSaga);
  yield takeLatest(A.updateReportRequest, handleUpdateReportRequestSaga);
  yield takeLatest(A.exportReportDataRequest, handleExportReportDataSaga);
  yield takeLatest(A.changeDefaultReportRequest, handleChangeDefaultReportSaga);
  yield takeLatest(A.changeCancelStatusRequest, handleChangeCancelStatusRequestSaga);
}

export default orderListWatcherSaga;
