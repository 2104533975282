import * as R from 'ramda';
import { connect, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import React, {
  useMemo,
  useState,
  useCallback,
} from 'react';
// features
import { makeSelectInitialDataLoadedStatus } from '../permission/selectors';
import { setExpandedContainerOptions } from '../expanded-container/actions';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// icons
import { truckOnMap, fleetsOnMap, driverOnMap, trailerOnMap } from '../../svgs';
// ui
import { PageWrapper, RelativeWrapper } from '../../ui';
// feature fleet-map
import FleetsMapComponent from './components/map';
import FiltersPanel from './components/filters-panel';
import { setEnterpriseFilter, setIdleTimeFilter } from './actions';
import InfoLengthContainer from './components/info-length-container';
import { makeSelectFleets, makeSelectGeoFencingLocation } from './selectors';
import { FleetsContent, MultiFleetContent } from './components/fleets-content';
import {
  mapFleets,
  groupByLatLng,
  fleetLNLNameMap,
  fleetTypeObjectMap,
  getLatLngFromFleets,
} from './helpers';
//////////////////////////////////////////////////

const fleetMapIcons = {
  all: fleetsOnMap,
  trucks: truckOnMap,
  drivers: driverOnMap,
  trailers: trailerOnMap,
};

const mapFleetsToContent = (
  entities: Array,
  fleetType: string,
  setExpandedContainerOptions: Function,
) => entities.map((entity: Array) => {
  const item = R.reduce((acc: Object, item: Object) => {
    const itemWithFleets = R.assoc('fleets', R.append(item.fleetObj, acc.fleets), item);

    return R.omit('fleetObj', itemWithFleets);
  }, { shortid: G.genShortId(), fleets: [] }, entity);

  const length = R.length(item.fleets);

  return {
    guid: item.shortid,
    latLng: item.latLng,
    infoContent: () => (
      <FleetsContent
        lnl={item.lnl}
        fleets={item.fleets}
        fleetType={fleetType}
        setExpandedContainerOptions={setExpandedContainerOptions}
      />
    ),
    markerContent: (
      <RelativeWrapper>
        {fleetMapIcons[fleetType]()}
        <InfoLengthContainer length={length} />
      </RelativeWrapper>
    ),
  };
});

const mapMultiFleetsToContent = (entities: Array, setExpandedContainerOptions: Function) =>
  entities.map((entity: Object) => {
    const { trucks, drivers, trailers } = entity;

    const trucksLength = R.length(trucks);
    const driversLength = R.length(drivers);
    const trailersLength = R.length(trailers);

    let latLng = null;

    if (R.gt(driversLength, 0)) {
      latLng = getLatLngFromFleets(drivers);
    } else if (R.gt(trucksLength, 0)) {
      latLng = getLatLngFromFleets(trucks);
    } else if (R.gt(trailersLength, 0)) {
      latLng = getLatLngFromFleets(trailers);
    }

    const length = R.sum([driversLength, trucksLength, trailersLength]);

    return {
      latLng,
      guid: G.genShortId(),
      infoContent: () => (
        <MultiFleetContent
          trucks={trucks}
          drivers={drivers}
          trailers={trailers}
          setExpandedContainerOptions={setExpandedContainerOptions}
        />
      ),
      markerContent: (
        <RelativeWrapper>
          {fleetMapIcons.all()}
          <InfoLengthContainer length={length} />
        </RelativeWrapper>
      ),
    };
  },
);

const groupFleets = (name: string, fleets: Array, groupedObj: Object = {}) => {
  const grouped = R.clone(groupedObj);

  fleets.forEach((item: Object) => {
    const empty = {
      trucks: [],
      drivers: [],
      trailers: [],
    };

    const lat = R.path(['latLng', 'lat'], item);
    const lng = R.path(['latLng', 'lng'], item);
    const latLng = `${lat}${lng}`;

    if (R.has(latLng, grouped)) {
      grouped[latLng][name].push(item);
    } else {
      grouped[latLng] = R.clone(empty);
      grouped[latLng][name].push(item);
    }
  });

  return grouped;
};

const getLocations = (props: Object, setExpandedContainerOptions: Function) => {
  const { trucks, fleets, drivers, trailers, fleetType } = props;

  if (R.and(R.equals(fleetType, 'all'), R.isNil(fleets))) {
    const grouped = groupFleets(
      'trailers',
      R.flatten(trailers),
      groupFleets(
        'trucks',
        R.flatten(trucks),
        groupFleets(
          'drivers',
          R.flatten(drivers),
        ),
      ),
    );

    return mapMultiFleetsToContent(R.values(grouped), setExpandedContainerOptions);
  }

  return mapFleetsToContent(fleets, fleetType, setExpandedContainerOptions);
};

const defaultFleetTypes = {
  [GC.FLEET_TRUCK_REPORT]: 'trucks',
  [GC.FLEET_TRAILER_REPORT]: 'trailers',
};

const FleetMap = ({
  fleets,
  reportType,
  geoFencingLocation,
}: Object) => {
  const {
    trucks,
    drivers,
    trailers,
    fleetsLoaded,
  } = fleets;

  const dispatch = useDispatch();

  const fromFleetList = G.isNotNilAndNotEmpty(reportType);
  const defaultFleetType = R.propOr('all', reportType, defaultFleetTypes);

  const [activeFleetType, setActiveFleetType] = useState(defaultFleetType);
  const [withoutClustering, setWithoutClustering] = useState(false);
  const [withoutGeoFencing, setWithoutGeoFencing] = useState(true);

  const trailersActive = R.equals(activeFleetType, 'trailers');

  const handleSetExpandedContainerOptions = useCallback((payload: Object) => {
    dispatch(setExpandedContainerOptions(payload));
  }, []);

  const handleSetWithoutClustering = useCallback(
    () => setWithoutClustering(R.not(withoutClustering)),
    [withoutClustering],
  );

  const handleSetWithoutGeoFencing = useCallback(
    () => setWithoutGeoFencing(R.not(withoutGeoFencing)),
    [withoutGeoFencing],
  );

  const handleSetEnterpriseFilter = useCallback(
    (enterpriseGuidList: any) => dispatch(setEnterpriseFilter(enterpriseGuidList)),
    [],
  );

  const handleSetIdleTimeFilter = useCallback(
    (idleTime: any) => dispatch(setIdleTimeFilter(idleTime)),
    [],
  );

  const trucksToUse = useMemo(() => R.values(mapFleets('truckLatestLocation', trucks)), [trucks]);
  const trailersToUse = useMemo(() => R.values(mapFleets('latestLocation', trailers)), [trailers]);
  const driversToUse = useMemo(() => R.values(mapFleets('driverLatestLocation', drivers)), [drivers]);

  const fleetsToUse = useMemo(
    () => groupByLatLng(fleetLNLNameMap[activeFleetType], fleets[fleetTypeObjectMap[activeFleetType]]),
    [trucks, drivers, trailers, activeFleetType],
  );

  const markerIcon = useMemo(() => fleetMapIcons[activeFleetType], [activeFleetType]);

  const locations = useMemo(
    () => getLocations({
      fleets: fleetsToUse,
      trucks: trucksToUse,
      drivers: driversToUse,
      trailers: trailersToUse,
      fleetType: activeFleetType,
    }, handleSetExpandedContainerOptions),
    [fleetsToUse, trucksToUse, driversToUse, trailersToUse, activeFleetType, handleSetExpandedContainerOptions]);

  const loaded = useMemo(() => R.all(R.equals(true), R.values(fleetsLoaded)), [fleetsLoaded]);

  const wrapperHeight = useMemo(() => G.ifElse(fromFleetList, 'calc(100% - 48px)', '100%'), [fromFleetList]);

  return (
    <PageWrapper
      pb='0'
      pl='0'
      minWidth='1280px'
      height={wrapperHeight}
      bgColor={G.getTheme('pages.layOutBgColor')}
    >
      <FiltersPanel
        loaded={loaded}
        reportType={reportType}
        trucksToUse={trucksToUse}
        driversToUse={driversToUse}
        fromFleetList={fromFleetList}
        trailersToUse={trailersToUse}
        trailersActive={trailersActive}
        defaultFleetType={defaultFleetType}
        withoutGeoFencing={withoutGeoFencing}
        withoutClustering={withoutClustering}
        setActiveFleetType={setActiveFleetType}
        geoFencingLocation={geoFencingLocation}
        handleSetIdleTimeFilter={handleSetIdleTimeFilter}
        handleSetEnterpriseFilter={handleSetEnterpriseFilter}
        handleSetWithoutClustering={handleSetWithoutClustering}
        handleSetWithoutGeoFencing={handleSetWithoutGeoFencing}
      />
      <FleetsMapComponent
        loaded={loaded}
        locations={locations}
        markerIcon={markerIcon}
        trailersActive={trailersActive}
        withoutClustering={withoutClustering}
        withoutGeoFencing={withoutGeoFencing}
        geoFencingLocation={geoFencingLocation}
      />
    </PageWrapper>
  );
};

const mapStateToProps = (state: Object) => createStructuredSelector({
  fleets: makeSelectFleets(state),
  geoFencingLocation: makeSelectGeoFencingLocation(state),
  initialDataLoaded: makeSelectInitialDataLoadedStatus(state),
});

export default connect(mapStateToProps)(FleetMap);
