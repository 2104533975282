import * as R from 'ramda';
import { all, put, call, fork, select, takeLatest } from 'redux-saga/effects';
// components
import { closeModal } from '../../../../components/modal/actions';
import { openLoader, closeLoader } from '../../../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../../../branch/selectors';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// sagas
import {
  crudSaga,
  visitPageSaga,
  openWindowSaga,
  getObjectWithCurrentBranchGuidParamSaga,
} from '../../../../sagas';
// utilities
import { sendRequest } from '../../../../utilities/http';
import endpointsMap from '../../../../utilities/endpoints';
// feature configs
import * as A from '../../actions';
import { getConfigsForInvoiceConfigSuccess } from './actions';
import {
  makeSelectGLCodeMappingList,
  makeSelectQBAccountMappingList,
  makeSelectSageAccountMappingList,
 } from '../../selectors';
import {
  getConfigByGroupSaga,
  getCustomerBranchListRequest,
  getSequencesByTypeForConfigSaga,
  getReferenceTypesByScopeForConfigSaga,
} from '../../sagas';
//////////////////////////////////////////////////

function* getCustomerInvoiceStatusesSaga() {
  try {
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.customerInvoiceStatuses, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerInvoiceStatusesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCustomerInvoiceStatusesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCustomerInvoiceStatusesSaga exception');
  }
}

export function* getDriverPayrollStatusesSaga() {
  try {
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.driverPayrollStatuses, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getDriverPayrollStatusesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDriverPayrollStatusesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getDriverPayrollStatusesSaga exception ');
  }
}

function* getCarrierInvoiceStatusesSaga() {
  try {
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.carrierInvoiceStatuses, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getCarrierInvoiceStatusesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCarrierInvoiceStatusesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getCarrierInvoiceStatusesSaga exception');
  }
}

function* getFleetServiceInvoiceStatusesSaga() {
  try {
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.fleetServiceInvoiceStatuses, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getFleetServiceInvoiceStatusesSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getFleetServiceInvoiceStatusesSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getFleetServiceInvoiceStatusesSaga exception');
  }
}

const returnInheritedMap = {
  endpoint: {
    [GC.INVOICE_DP_STATUS]: endpointsMap.restoreDriverPayrollStatuses,
    [GC.INVOICE_CI_STATUS]: endpointsMap.restoreCustomerInvoiceStatuses,
    [GC.INVOICE_CARRIER_STATUS]: endpointsMap.restoreCarrierInvoiceStatuses,
    [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: endpointsMap.restoreFleetServiceInvoiceStatuses,
  },
  successCallSaga: {
    [GC.INVOICE_DP_STATUS]: getDriverPayrollStatusesSaga,
    [GC.INVOICE_CI_STATUS]: getCustomerInvoiceStatusesSaga,
    [GC.INVOICE_CARRIER_STATUS]: getCarrierInvoiceStatusesSaga,
    [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: getFleetServiceInvoiceStatusesSaga,
  },
};

function* handleReturnInheritedInvoiceStatusesSaga({ payload }: string) {
  try {
    yield put(openLoader());
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const endpoint = R.path(['endpoint', payload], returnInheritedMap);
    const successCallSaga = R.path(['successCallSaga', payload], returnInheritedMap);
    const res = yield call(sendRequest, 'put', endpoint, options);
    if (G.isResponseSuccess(res.status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield call(successCallSaga);
    } else {
      yield call(G.handleFailResponse, res, 'handleReturnInheritedInvoiceStatusesSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleReturnInheritedInvoiceStatusesSaga exception');
  }
}

const createUpdateMap = {
  create: {
    method: 'post',
    endpoint: {
      [GC.INVOICE_DP_STATUS]: endpointsMap.driverPayrollStatus,
      [GC.INVOICE_CI_STATUS]: endpointsMap.customerInvoiceStatus,
      [GC.INVOICE_CARRIER_STATUS]: endpointsMap.carrierInvoiceStatus,
      [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: endpointsMap.fleetServiceInvoiceStatus,
    },
    successAction: {
      [GC.INVOICE_DP_STATUS]: A.createDriverPayrollStatusSuccess,
      [GC.INVOICE_CI_STATUS]: A.createCustomerInvoiceStatusesSuccess,
      [GC.INVOICE_CARRIER_STATUS]: A.createCarrierInvoiceStatusSuccess,
      [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: A.createOrUpdateFleetServiceInvoiceStatusSuccess,
    },
  },
  update: {
    method: 'put',
    endpoint: {
      [GC.INVOICE_DP_STATUS]: endpointsMap.driverPayrollStatus,
      [GC.INVOICE_CI_STATUS]: endpointsMap.customerInvoiceStatus,
      [GC.INVOICE_CARRIER_STATUS]: endpointsMap.carrierInvoiceStatus,
      [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: endpointsMap.fleetServiceInvoiceStatus,
    },
    successAction: {
      [GC.INVOICE_DP_STATUS]: A.updateDriverPayrollStatusSuccess,
      [GC.INVOICE_CI_STATUS]: A.updateCustomerInvoiceStatusSuccess,
      [GC.INVOICE_CARRIER_STATUS]: A.updateCarrierInvoiceStatusSuccess,
      [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: A.createOrUpdateFleetServiceInvoiceStatusSuccess,
    },
  },
};

const carrierFields = [
  GC.FIELD_GUID,
  GC.FIELD_VERSION,
  GC.FIELD_BRANCH_GUID,
  GC.FIELD_STORED_VALUE,
  GC.FIELD_SYSTEM_STATUS,
  GC.FIELD_DISPLAYED_VALUE,
  GC.FIELD_INTEGRATION_STATUS,
];

function* handleCreateUpdateInvoiceStatusSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const { action, values, configName } = payload;
    let reqData = values;
    if (R.equals(action, 'create')) {
      reqData = R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, values);
    }
    if (
      R.and(
        R.equals(action, 'update'),
        R.equals(configName, GC.INVOICE_CARRIER_STATUS),
      )
    ) {
      reqData = R.pick(carrierFields, values);
    }
    const method = R.path([action, 'method'], createUpdateMap);
    const endpoint = R.path([action, 'endpoint', configName], createUpdateMap);
    const successAction = R.path([action, 'successAction', configName], createUpdateMap);
    const res = yield call(sendRequest, method, endpoint, { data: reqData });
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(successAction(data));
    } else {
      yield call(G.handleFailResponse, res, 'handleCreateUpdateInvoiceStatusSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleCreateUpdateInvoiceStatusSaga exception');
  }
}

const deleteStatusMap = {
  endpoint: {
    [GC.INVOICE_DP_STATUS]: endpointsMap.getDeleteDriverPayrollStatus,
    [GC.INVOICE_CI_STATUS]: endpointsMap.getDeleteCustomerInvoiceStatus,
    [GC.INVOICE_CARRIER_STATUS]: endpointsMap.getDeleteCarrierInvoiceStatus,
    [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: endpointsMap.getDeleteFleetServiceInvoiceStatus,
  },
  successAction: {
    [GC.INVOICE_DP_STATUS]: A.deleteDriverPayrollStatusSuccess,
    [GC.INVOICE_CI_STATUS]: A.deleteCustomerInvoiceStatusSuccess,
    [GC.INVOICE_CARRIER_STATUS]: A.deleteCarrierInvoiceStatusSuccess,
    [GC.INVOICE_FLEET_SERVICE_INVOICE_STATUS]: A.deleteFleetServiceInvoiceStatusSuccess,
  },
};

export function* handleDeleteInvoiceStatusSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const { entityGuid, configName } = payload;
    const endpoint = R.path(['endpoint', configName], deleteStatusMap)(entityGuid);
    const successAction = R.path(['successAction', configName], deleteStatusMap);
    const res = yield call(sendRequest, 'delete', endpoint);
    if (G.isResponseSuccess(res.status)) {
      yield put(successAction(entityGuid));
    } else {
      yield call(G.handleFailResponse, res, 'handleDeleteInvoiceStatusSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDeleteInvoiceStatusSaga exception');
  }
}

function* handleConnectToAccountingSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.quickBooksConnect, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.connectToFinancialSuccess(R.assoc('type', payload, data)));
      yield call(openWindowSaga, { url: data, name: 'quickBooksConnect', reloadLocation: true });
    } else {
      yield call(G.handleFailResponse, res, 'handleConnectToAccountingSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleConnectToAccountingSaga exception');
  }
}

function* handleDisconnectFromAccountingSaga({ payload }: Object) {
  try {
    yield put(openLoader());
    const options = yield call(getObjectWithCurrentBranchGuidParamSaga);
    const res = yield call(sendRequest, 'get', endpointsMap.quickBooksDisconnect, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield call(G.showToastrMessage, 'success', 'messages:success:save');
      yield put(A.disconnectFromFinancialSuccess(R.assoc('type', payload, data)));
      location.reload(); // eslint-disable-line
    } else {
      yield call(G.handleFailResponse, res, 'handleDisconnectFromAccountingSaga fail', true);
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'handleDisconnectFromAccountingSaga exception');
  }
}

// qb account mapping
function* getQBAccountMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.quickBooksAccountMappingList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getQBAccountMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getQBAccountMappingListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getQBAccountMappingListRequest exception');
  }
}

function* createOrUpdateQBAccountMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.quickBooksAccountMapping, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateQBAccountMappingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateQBAccountMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateQBAccountMappingRequest exception');
  }
}

function* removeQBAccountMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const qbAccountMappingList = yield select(makeSelectQBAccountMappingList());
    const qbAccountMapping = R.find(R.propEq(payload, GC.FIELD_GUID), R.or(qbAccountMappingList, []));
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.quickBooksAccountMapping, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeQBAccountMappingSuccess({
        [GC.FIELD_GUID]: G.getGuidFromObject(qbAccountMapping),
        [GC.FIELD_SCOPE]: G.getPropFromObject(GC.FIELD_SCOPE, qbAccountMapping),
      }));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeQBAccountMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeQBAccountMappingRequest exception');
  }
}

// sage account mapping
function* getSageAccountMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.sageAccountMappingList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getSageAccountMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getSageAccountMappingListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getSageAccountMappingListRequest exception');
  }
}

function* createOrUpdateSageAccountMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.sageAccountMapping, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateSageAccountMappingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateSageAccountMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateSageAccountMappingRequest exception');
  }
}

function* removeSageAccountMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const list = yield select(makeSelectSageAccountMappingList());
    const accountMapping = R.find(R.propEq(payload, GC.FIELD_GUID), R.or(list, []));
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.sageAccountMapping, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeSageAccountMappingSuccess({
        [GC.FIELD_GUID]: G.getGuidFromObject(accountMapping),
        [GC.FIELD_SCOPE]: G.getPropFromObject(GC.FIELD_SCOPE, accountMapping),
      }));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeSageAccountMappingRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeSageAccountMappingRequest exception');
  }
}

// customer id mapping
function* getCustomerIdMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.customerIdMappingList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getCustomerIdMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getCustomerIdMappingListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getCustomerIdMappingListRequest exception');
  }
}

function* createOrUpdateCustomerIdMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.customerIdMapping, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateCustomerIdMappingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateCustomerIdMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateCustomerIdMappingRequest exception');
  }
}

function* removeCustomerIdMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.customerIdMapping, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeCustomerIdMappingSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeCustomerIdMappingRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeCustomerIdMappingRequest exception');
  }
}

// department id mapping
function* getDepartmentIdMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.departmentIdMappingList, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.getDepartmentIdMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getDepartmentIdMappingListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getDepartmentIdMappingListRequest exception');
  }
}

function* createOrUpdateDepartmentIdMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.departmentIdMapping, options);
    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateDepartmentIdMappingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateDepartmentIdMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateDepartmentIdMappingRequest exception');
  }
}

function* removeDepartmentIdMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.departmentIdMapping, options);
    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeDepartmentIdMappingSuccess(payload));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeDepartmentIdMappingRequest fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeDepartmentIdMappingRequest exception');
  }
}

// gl code mapping
function* getGLCodeMappingListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.glCodeMappingList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getGLCodeMappingListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getGLCodeMappingListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getGLCodeMappingListRequest exception');
  }
}

function* createOrUpdateCLCodeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.glCodeMapping, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateCLCodeMappingSuccess(data));
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateCLCodeMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateCLCodeMappingRequest exception');
  }
}

function* removeGLCodeMappingRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const glCodeMappingList = yield select(makeSelectGLCodeMappingList());
    const glCodeMapping = R.find(R.propEq(payload, GC.FIELD_GUID), R.or(glCodeMappingList, []));
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.glCodeMapping, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.removeGLCodeMappingSuccess({
        [GC.FIELD_GUID]: G.getGuidFromObject(glCodeMapping),
        [GC.FIELD_SCOPE]: G.getPropFromObject(GC.FIELD_SCOPE, glCodeMapping),
      }));
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeGLCodeMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeGLCodeMappingRequest exception');
  }
}

// invoice status rule
function* getInvoiceStatusRuleListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: { [GC.BRANCH_GUID]: branchGuid },
    };
    const res = yield call(sendRequest, 'get', endpointsMap.defaultInvoiceStatusRuleList, options);
    const { data, status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getInvoiceStatusRuleListSuccess(data));
    } else {
      yield call(G.handleFailResponse, res, 'getInvoiceStatusRuleListRequest fail');
    }
  } catch (error) {
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'getInvoiceStatusRuleListRequest exception');
  }
}

function* createOrUpdateInvoiceStatusRuleRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const currentBranchGuid = yield select(makeSelectCurrentBranchGuid());
    const method = G.ifElse(
      G.isNilOrEmpty(G.getPropFromObject(GC.FIELD_VERSION, payload)),
      'post',
      'put',
    );
    const branchGuid = R.pathOr(currentBranchGuid, [GC.BRANCH_GUID], payload);
    const options = {
      data: R.assoc(GC.BRANCH_GUID, branchGuid, payload),
    };
    const res = yield call(sendRequest, method, endpointsMap.defaultInvoiceStatusRule, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.getInvoiceStatusRuleListRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateCLCodeMappingRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'createOrUpdateCLCodeMappingRequest exception');
  }
}

function* removeInvoiceStatusRuleRequest({ payload }: Object) {
  try {
    yield put(openLoader());
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };
    const res = yield call(sendRequest, 'delete', endpointsMap.defaultInvoiceStatusRule, options);
    const { status } = res;
    if (G.isResponseSuccess(status)) {
      yield put(A.getInvoiceStatusRuleListRequest());
      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeInvoiceStatusRuleRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());
    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'removeInvoiceStatusRuleRequest exception');
  }
}

// expense types
function* getExpenseTypeListRequest() {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: {
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };

    yield call(crudSaga, {
      options,
      method: 'get',
      successAction: A.getExpenseTypeListSuccess,
      parentSagaName: 'getExpenseTypeListRequest',
      endpoint: endpointsMap.driverExpenseTypeList,
    });
  } catch (error) {
    yield call(G.handleException, error, 'getExpenseTypeListRequest exception');
  }
}

function* createOrUpdateExpenseTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const method = G.ifElse(
      G.isNotNilAndNotEmpty(payload.version),
      'put',
      'post',
    );

    const options = {
      data: R.assoc(GC.FIELD_BRANCH_GUID, branchGuid, payload),
    };

    const res = yield call(sendRequest, method, endpointsMap.driverExpenseType, options);

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(closeModal());
      yield put(A.createOrUpdateExpenseTypeSuccess(data));

      yield call(G.showToastrMessage, 'success', 'messages:success:200-201');
    } else {
      yield call(G.handleFailResponse, res, 'createOrUpdateExpenseTypeRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'createOrUpdateExpenseTypeRequest exception');
  }
}

function* removeExpenseTypeRequest({ payload }: Object) {
  try {
    yield put(openLoader());

    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      data: {
        [GC.FIELD_GUID]: payload,
        [GC.BRANCH_GUID]: branchGuid,
      },
    };

    const res = yield call(sendRequest, 'delete', endpointsMap.driverExpenseType, options);

    const { status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(A.removeExpenseTypeSuccess(payload));

      yield call(G.showToastrMessage, 'success', 'messages:success:204');
    } else {
      yield call(G.handleFailResponse, res, 'removeExpenseTypeRequest fail');
    }
    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.handleException, error, 'removeExpenseTypeRequest exception');
  }
}
// expense types

function* getConfigsForInvoiceConfigSaga() {
  try {
    const names = R.join(',', [
      GC.CARRIER_DOCUMENT_TYPE,
      GC.CLO_ORDER_TYPE_DROPDOWN,
      GC.GENERAL_MODE_TRANSPORTATION,
    ]);
    const branchGuid = yield select(makeSelectCurrentBranchGuid());
    const options = {
      params: {
        names,
        [GC.FIELD_BRANCH_GUID]: branchGuid,
      },
    };
    yield call(crudSaga, {
      options,
      method: 'get',
      shouldCloseModal: true,
      mapper: G.mapConfigValuesByName,
      endpoint: endpointsMap.branchConfigsEndpoint,
      successAction: getConfigsForInvoiceConfigSuccess,
      parentSagaName: 'getConfigsForInvoiceConfigSaga',
    });
  } catch (error) {
    yield call(G.handleException, error, 'getConfigsForInvoiceConfigSaga exception');
  }
}

function* handleVisitConfigInvoicesPageSaga({ payload }: Object) {
  while (true) { // eslint-disable-line
    yield call(visitPageSaga, payload, GC.CHECK_VISIT_CONFIG_INVOICES_PAGE);
    yield put(openLoader({ showDimmer: true }));
    yield put(A.getAvailableDocumentTypes());
    yield put(A.getAccessorialForConfigRequest());
    yield fork(getConfigsForInvoiceConfigSaga);
    yield all([
      call(getConfigByGroupSaga, GC.INVOICE_CONFIG_GROUP),
      call(getSequencesByTypeForConfigSaga, { payload: GC.SEQUENCE_TYPE_INVOICE }),
      call(getReferenceTypesByScopeForConfigSaga, { payload: GC.REF_SCOPE_NAME_CLO }),
      call(getDriverPayrollStatusesSaga),
      call(getCarrierInvoiceStatusesSaga),
      call(getCustomerInvoiceStatusesSaga),
      call(getQBAccountMappingListRequest),
      call(getSageAccountMappingListRequest),
      call(getCustomerIdMappingListRequest),
      call(getDepartmentIdMappingListRequest),
      call(getCustomerBranchListRequest),
      call(getGLCodeMappingListRequest),
      call(getInvoiceStatusRuleListRequest),
      call(getFleetServiceInvoiceStatusesSaga),
      call(getExpenseTypeListRequest),
    ]);
    yield put(closeLoader());
    break;
  }
}

function* invoicesConfigWatcherSaga() {
  yield takeLatest(A.connectToAccountingRequest, handleConnectToAccountingSaga);
  yield takeLatest(A.deleteInvoiceStatusRequest, handleDeleteInvoiceStatusSaga);
  yield takeLatest(A.getDriverPayrollStatusesRequest, getDriverPayrollStatusesSaga);
  yield takeLatest(GC.VISIT_CONFIG_INVOICES_PAGE, handleVisitConfigInvoicesPageSaga);
  yield takeLatest(A.getCarrierInvoiceStatusesRequest, getCarrierInvoiceStatusesSaga);
  yield takeLatest(A.getCustomerInvoiceStatusesRequest, getCustomerInvoiceStatusesSaga);
  yield takeLatest(A.disconnectFromAccountingRequest, handleDisconnectFromAccountingSaga);
  yield takeLatest(A.createUpdateInvoiceStatusRequest, handleCreateUpdateInvoiceStatusSaga);
  yield takeLatest(A.returnInheritedStatusesRequest, handleReturnInheritedInvoiceStatusesSaga);
  // account mapping
  yield takeLatest(A.removeQBAccountMappingRequest, removeQBAccountMappingRequest);
  yield takeLatest(A.getQBAccountMappingListRequest, getQBAccountMappingListRequest);
  yield takeLatest(A.createOrUpdateQBAccountMappingRequest, createOrUpdateQBAccountMappingRequest);
  // sage account mapping
  yield takeLatest(A.removeSageAccountMappingRequest, removeSageAccountMappingRequest);
  yield takeLatest(A.getSageAccountMappingListRequest, getSageAccountMappingListRequest);
  yield takeLatest(A.createOrUpdateSageAccountMappingRequest, createOrUpdateSageAccountMappingRequest);
  // customer id mapping
  yield takeLatest(A.removeCustomerIdMappingRequest, removeCustomerIdMappingRequest);
  yield takeLatest(A.getCustomerIdMappingListRequest, getCustomerIdMappingListRequest);
  yield takeLatest(A.createOrUpdateCustomerIdMappingRequest, createOrUpdateCustomerIdMappingRequest);
  // department id mapping
  yield takeLatest(A.removeDepartmentIdMappingRequest, removeDepartmentIdMappingRequest);
  yield takeLatest(A.getDepartmentIdMappingListRequest, getDepartmentIdMappingListRequest);
  yield takeLatest(A.createOrUpdateDepartmentIdMappingRequest, createOrUpdateDepartmentIdMappingRequest);
  // gl code mapping
  yield takeLatest(A.removeGLCodeMappingRequest, removeGLCodeMappingRequest);
  yield takeLatest(A.getGLCodeMappingListRequest, getGLCodeMappingListRequest);
  yield takeLatest(A.createOrUpdateCLCodeMappingRequest, createOrUpdateCLCodeMappingRequest);
  // invoice status rule
  yield takeLatest(A.removeInvoiceStatusRuleRequest, removeInvoiceStatusRuleRequest);
  yield takeLatest(A.getInvoiceStatusRuleListRequest, getInvoiceStatusRuleListRequest);
  yield takeLatest(A.createOrUpdateInvoiceStatusRuleRequest, createOrUpdateInvoiceStatusRuleRequest);
  // expense types
  yield takeLatest(A.removeExpenseTypeRequest, removeExpenseTypeRequest);
  yield takeLatest(A.getExpenseTypeListRequest, getExpenseTypeListRequest);
  yield takeLatest(A.createOrUpdateExpenseTypeRequest, createOrUpdateExpenseTypeRequest);
}

export default invoicesConfigWatcherSaga;
