import * as R from 'ramda';
import React from 'react';
import { withFormik } from 'formik';
import { pure, compose } from 'react-recompose';
// components
import { FormFooter } from '../../../../../components/form-footer';
// forms
import { Fieldset2 } from '../../../../../forms';
// helpers/constants
import * as G from '../../../../../helpers';
import * as GC from '../../../../../constants';
// ui
import { Box } from '../../../../../ui';
// feature config
import {
  dispatchIntegrationFieldSettings,
  defaultDispatchIntegrationFields,
  dispatchIntegrationValidationSchemaObject,
} from '../settings';
//////////////////////////////////////////////////

const truckerToolsFields = [GC.FIELD_TYPE, GC.FIELD_NAME, GC.FIELD_ACCOUNT_ID];
const fourKitesFields = [
  GC.FIELD_TYPE,
  GC.FIELD_NAME,
  GC.FIELD_API_KEY,
  GC.FIELD_TRACKING_STATUS_CODE,
  GC.FIELD_SEND_AUTOMATICALLY,
];
const macroPointFields = [
  GC.FIELD_TYPE,
  GC.FIELD_NAME,
  GC.FIELD_LOGIN,
  GC.FIELD_PASSWORD,
  GC.FIELD_INTEGRATION_ID,
  GC.FIELD_TRACKING_STATUS_CODE,
  GC.FIELD_TRACKING_FREQUENCY_IN_MINUTES,
  GC.FIELD_HOT_LOAD_TRACKING_FREQUENCY_IN_MINUTES,
  GC.FIELD_SEND_AUTOMATICALLY,
];

const fieldsToPick = ({ type }: Object) => {
  const map = {
    [GC.DISPATCH_INTEGRATION_TYPE_FOUR_KITES]: fourKitesFields,
    [GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT]: macroPointFields,
    [GC.DISPATCH_INTEGRATION_TYPE_TRUCKER_TOOLS]: truckerToolsFields,
  };

  return R.pathOr([], [type], map);
};

const enhance = compose(
  withFormik({
    validationSchema: dispatchIntegrationValidationSchemaObject,
    handleSubmit: (values: Object, { props }: Object) => props.submitAction(values),
    mapPropsToValues: ({ initialValues }: Object) => G.setInitialFormikValues(
      defaultDispatchIntegrationFields,
      initialValues,
    ),
  }),
  pure,
);

const DispatchIntegrationForm = (props: Object) => (
  <Box
    width={G.ifElse(R.pathEq(GC.DISPATCH_INTEGRATION_TYPE_MACRO_POINT, ['values', GC.FIELD_TYPE], props), 580, 270)}
  >
    <form onSubmit={props.handleSubmit}>
      <Fieldset2
        {...G.getFormikProps(props)}
        justifyContent='space-between'
        optionsForSelect={props.optionsForSelect}
        handlers={{ disableIntegrationType: () => props.isEditMode }}
        fieldsWrapperStyles={{ pt: 11, justifyContent: 'space-between' }}
        fields={R.values(R.pick(fieldsToPick(props.values), dispatchIntegrationFieldSettings))}
      />
      <FormFooter />
    </form>
  </Box>
);

export default enhance(DispatchIntegrationForm);
