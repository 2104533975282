import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useMemo, useCallback } from 'react';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// features
import { makeSelectAllAvailableReferenceTypesByScopeName } from '../../reference/selectors';
//////////////////////////////////////////////////

const useExportExcel = (props: Object, filterProps: Array) => {
  const { selectedReport, exportReportDataRequest } = props;

  const allAvailableRefTypesByScopeName = useSelector(makeSelectAllAvailableReferenceTypesByScopeName());

  const filterBy = useMemo(() => {
    const references = G.getRefListByReportType(
      allAvailableRefTypesByScopeName,
      R.path([GC.FIELD_TYPE], selectedReport),
    );

    return G.setFilterByParams(references, filterProps);
  }, [filterProps, selectedReport, allAvailableRefTypesByScopeName]);

  const handleExportAction = useCallback((showAllEvents: boolean) => {
    const fields = R.pathOr({}, ['fields'], selectedReport);
    const report = R.assoc('fields', G.addDisplayNameToReportFields(fields, filterBy), selectedReport);

    exportReportDataRequest({
      ...report,
      showAllEvents,
      fileType: 'xlsx',
    });
  }, [filterBy, selectedReport, exportReportDataRequest]);

  const exportFileTypes = useMemo(() => (
    [
      {
        label: 'xls',
        action: () => handleExportAction(false),
        text: G.getWindowLocale('actions:export-exl', 'Generate Excel'),
      },
      {
        label: 'xls',
        action: () => handleExportAction(true),
        text: G.getWindowLocale('actions:export-exl-with-stops', 'Generate Excel With Stops'),
      },
    ]
  ), [handleExportAction]);

  return exportFileTypes;
};

export default useExportExcel;
