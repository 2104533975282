import * as R from 'ramda';
import parse from 'html-react-parser';
import { css } from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import React, { memo, useRef, useState, Fragment, useEffect, useCallback, useLayoutEffect } from 'react';
// components
import { TextComponent } from '../../../../components/text';
import { FormFooter2 } from '../../../../components/form-footer';
import {
  getOperations,
  toolbarSettings,
  renderConfirmationModal,
} from '../../../../components/activities';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
// icons
import * as I from '../../../../svgs';
// utilities
import endpointsMap from '../../../../utilities/endpoints';
import { sendRequest, sendRequestWithQSParamsSerializer } from '../../../../utilities/http';
// ui
import { Box, Span, Flex, EditorWrapper, MainActionButton, scrollableContainerCss4px } from '../../../../ui';
//////////////////////////////////////////////////

// TODO: finish when API ready and check common solution with work order notes

const whiteColor = G.getTheme('colors.white');
const darkGreyColor = G.getTheme('colors.#7D828C');
const lightGreyColor = G.getTheme('colors.lightGrey');
const greyMatterhornColor = G.getTheme('colors.greyMatterhorn');

const wrapperStyles = {
  mb: 10,
  p: '7px',
  borderRadius: '4px',
  border: '1px solid',
  color: greyMatterhornColor,
  borderColor: lightGreyColor,
};

const footerBtnStyles = { width: 100, height: 25, fontSize: 13 };

const EditorComponent = ({ value, setValue, setIsEditing, additionalStyles }: Object) => {
  const [editorState, setEditorState] = useState(() => {
    if (G.isString(value)) return G.createEditorState(value);

    return value;
  });

  const ref = useRef();

  const handleSaveChanges = useCallback(() => {
    const value = G.convertHtmlToString(editorState);

    if (R.isEmpty(R.trim(R.replace(/(<([^>]+)>)/ig, '', value)))) return;

    setValue(value);
  }, [editorState]);

  useLayoutEffect(() => {
    ref.current.focus();

    setEditorState(G.moveFocusToEnd(editorState));
  }, []);

  return (
    <Fragment>
      <EditorWrapper
        width='100%'
        bg={whiteColor}
        css={additionalStyles}
      >
        <Editor
          stripPastedStyles={true}
          toolbar={toolbarSettings}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          editorRef={(editorRef: Object) => ref.current = editorRef}
        />
      </EditorWrapper>
      <FormFooter2
        submitAction={handleSaveChanges}
        cancelBtnStyles={footerBtnStyles}
        submitBtnStyles={footerBtnStyles}
        boxStyles={{ mt: 15, width: 220 }}
        cancelAction={() => setIsEditing(false)}
      />
    </Fragment>
  );
};

const Note = memo((props: Object) => {
  const { note, index, deleteNote, openModal, closeModal, updateNote, handleNotes } = props;

  const [editMode, setEditMode] = useState(false);

  const { guid, text, createdBy, createdDate, lastModifiedBy, lastModifiedDate } = note;

  return (
    <Box {...wrapperStyles} pb='0px'>
      <Flex width='100%' justifyContent='space-between'>
        {/* <Flex alignItems='flex-start'>
          <Flex
            width={30}
            height={30}
            title={createdBy}
            border='1px solid'
            borderRadius='50%'
            justifyContent='center'
            textTransform='uppercase'
            color={greyMatterhornColor}
            borderColor={lightGreyColor}
          >
            {R.take(1, createdBy)}
          </Flex>
          <Box ml={10} fontSize={12} color={greyMatterhornColor}>
            <TextComponent maxWidth={400} display='block' title={createdBy} withEllipsis={true}>
              {createdBy}
            </TextComponent>
            <Flex mt='5px' color={darkGreyColor}>
              {G.convertDateTimeToConfigFormat(G.ifElse(G.isNotNil(lastModifiedDate), lastModifiedDate, createdDate))}
              {
                G.notEquals(createdDate, lastModifiedDate) &&
                <Flex>
                  <Span ml={10}>
                    {G.getWindowLocale('titles:edited', 'Edited')}
                  </Span>
                  <TextComponent ml='5px' maxWidth={250} display='block' withEllipsis={true} title={lastModifiedBy}>
                    ({lastModifiedBy})
                  </TextComponent>
                </Flex>
              }
            </Flex>
          </Box>
        </Flex> */}
        <Flex>
          <Box cursor='pointer' onClick={() => setEditMode(true)}>
            {I.pencil(darkGreyColor)}
          </Box>
          <Box
            ml={10}
            cursor='pointer'
            onClick={() =>
              renderConfirmationModal({ openModal, closeModal, action: () => deleteNote(index) })
              // renderConfirmationModal({ openModal, closeModal, action: () => handleNotes('delete', {}, guid) })
            }
          >
            {I.trash(darkGreyColor)}
          </Box>
        </Flex>
      </Flex>
      <Box my={15} color={greyMatterhornColor}>
        {
          editMode ? (
            <EditorComponent
              value={text}
              setIsEditing={setEditMode}
              setValue={(text: string) => {
                updateNote(index, R.assoc(GC.FIELD_TEXT, text, note));

                setEditMode(false);
              }}
              // setValue={(text: string) =>
              //   handleNotes('put', { data: R.assoc(GC.FIELD_TEXT, text, note) }, guid, () => setEditMode(false))
              // }
              additionalStyles={css`
                & .DraftEditor-root {
                  overflow-y: auto;
                  max-height: 350px;

                  ${scrollableContainerCss4px}
                }
              `}
            />
          ) : (
            <Box wordBreak='break-word'>
              {parse(text)}
            </Box>
          )
        }
      </Box>
    </Box>
  );
});

const AddNote = ({ addNote, editMode, setEditMode, handleNotes, workOrderGuid }: Object) => (
  <Box my={15} px={15}>
    {
      editMode ? (
        <EditorComponent
          value=''
          editMode={true}
          setIsEditing={setEditMode}
          setValue={(text: string) => {
            addNote({ text, workOrderGuid });

            setEditMode(false);
          }
            // handleNotes('post', { data: { text, workOrderGuid } }, null, () => setEditMode(false))
          }
          additionalStyles={css`
            & .DraftEditor-root {
              height: 100px;
              overflow-y: auto;

              ${scrollableContainerCss4px}
            }
          `}
        />
      ) : (
        <Flex
          pl={15}
          height={32}
          width='100%'
          cursor='text'
          bg={whiteColor}
          borderRadius='4px'
          border='1px solid'
          color={darkGreyColor}
          borderColor={lightGreyColor}
          onClick={() => setEditMode(true)}
        >
          {G.getWindowLocale('titles:add-note', 'Add Note')}
        </Flex>
      )
    }
  </Box>
);

const Notes = (props: Object) => {
  const {
    addNote,
    openModal,
    updateNote,
    deleteNote,
    closeModal,
    openLoader,
    closeLoader,
    setActivities,
    maintenanceNotes,
    primaryObjectGuid,
  } = props;

  const [addNoteMode, setAddNoteMode] = useState(false);

  // console.log('///-maintenanceNotes', maintenanceNotes);

  // const handleNotes = useCallback(async (
  //   method: string,
  //   options: Object,
  //   noteGuid: string,
  //   successCallback: Function,
  // ) => {
  //   openLoader();

  //   const operations = getOperations(noteGuid);

  //   const endpoints = {
  //     put: endpointsMap.workOrderNote,
  //     post: endpointsMap.workOrderNote,
  //     get: endpointsMap.workOrderNoteList,
  //     delete: endpointsMap.getWorkOrderNoteEndpoint(noteGuid),
  //   };

  //   const res = await sendRequest(
  //     method,
  //     R.prop(method, endpoints),
  //     {
  //       ...options,
  //       params: G.ifElse(R.equals(method, 'get'), { [GC.FIELD_WORK_ORDER_GUID]: primaryObjectGuid }, null),
  //     },
  //   );

  //   const { data, status } = res;

  //   if (G.isResponseSuccess(status)) {
  //     setActivities((prev: Object) => {
  //       const newNotes = R.call(R.prop(method, operations), R.prop(GC.FIELD_NOTES, prev), data);

  //       return R.assoc(GC.FIELD_NOTES, newNotes, prev);
  //     });

  //     G.callFunction(successCallback);
  //   } else {
  //     G.handleFailResponseSimple(res, 'handleNotes fail');
  //   }

  //   closeLoader();
  // }, [primaryObjectGuid]);

  // useEffect(() => {
  //   if (R.isNil(maintenanceNotes)) handleNotes('get');
  // }, [maintenanceNotes, handleNotes]);

  // if (R.isNil(maintenanceNotes)) return null;

  return (
    <Fragment>
      <Box
        mr={10}
        overflowY='auto'
        p='15px 5px 0 15px'
        height='calc(100% - 160px)'
        // height={`calc(100% - ${G.ifElse(addNoteMode, 310, 109)}px)`}
        css={scrollableContainerCss4px}
      >
        {
          R.or(maintenanceNotes, []).map((note: Object, index: number) => (
            <Note
              note={note}
              index={index}
              openModal={openModal}
              updateNote={updateNote}
              deleteNote={deleteNote}
              closeModal={closeModal}
              // handleNotes={handleNotes}
              key={G.getGuidFromObject(note) || index}
            />
          ))
        }
      </Box>
      <AddNote
        addNote={addNote}
        editMode={addNoteMode}
        // handleNotes={handleNotes}
        setEditMode={setAddNoteMode}
        workOrderGuid={primaryObjectGuid}
      />
    </Fragment>
  );
};

export default Notes;
