import L from 'leaflet';
import * as R from 'ramda';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { Circle, Marker, CircleMarker } from 'react-leaflet';
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
// components
import Map from '../../../components/simple-map-with-markers';
import { openModal, closeModal } from '../../../components/modal/actions';
import { FleetMarkerWithInfo } from '../../../components/map/components/marker-with-info';
// features
import {
  getFleetTrailerListSuccess,
  getGeoFencingLocationRequest,
  getGeoFencingLocationSuccess,
} from '../../geo-fencing-location/actions';
import { VIEW_MODE, feetToMeters } from '../../geo-fencing-location/helpers';
import { makeSelectCurrentLocation } from '../../geo-fencing-location/selectors';
import ViewLocationPopup from '../../geo-fencing-location/components/view-location-popup';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Flex } from '../../../ui';
// feature fleet-map
import { convertRadiusFromMetersToPixels } from '../helpers';
//////////////////////////////////////////////////

const blueColor = G.getTheme('colors.light.blue');

const markerCircleRadius = 18;
const iconSize = R.multiply(markerCircleRadius, 2);

const getIcon = (count: number) => L.divIcon({
  className: '',
  iconAnchor: [markerCircleRadius, markerCircleRadius],
  html: renderToString(
    <Flex
      fontSize={16}
      width={iconSize}
      color={blueColor}
      fontWeight='bold'
      height={iconSize}
      alignItems='center'
      justifyContent='center'
    >
      {count}
    </Flex>),
});

const GeoFencingLocation = ({
  zoom,
  viewLocation,
  trailersActive,
  withoutGeoFencing,
  geoFencingLocation,
}: Object) => {
  if (R.or(withoutGeoFencing, R.not(trailersActive))) return null;

  return (
    G.isNotNilAndNotEmpty(geoFencingLocation)
    && R.map((location: Object) => {
      const {
        guid,
        radius,
        latitude,
        longitude,
        radiusUom,
        trailerCount,
      } = location;

      const center = [latitude, longitude];

      const circleRadius = G.ifElse(R.equals(radiusUom, GC.UOM_METER), radius, +feetToMeters(radius));

      const radiusInPixels = convertRadiusFromMetersToPixels(zoom, circleRadius, latitude);

      return (
        <Fragment key={guid}>
          <Marker position={center} icon={getIcon(trailerCount)} onClick={() => viewLocation(guid)} />
          { R.gte(radiusInPixels, markerCircleRadius) &&
            <Circle
              center={center}
              radius={circleRadius}
              onClick={() => viewLocation(guid)}
            />
          }
          { R.lt(radiusInPixels, markerCircleRadius) &&
            <CircleMarker
              center={center}
              radius={markerCircleRadius}
              onClick={() => viewLocation(guid)}
            />
          }
        </Fragment>
      );
    }, geoFencingLocation)
  );
};

const FleetsMapComponent = ({
  loaded,
  locations,
  markerIcon,
  trailersActive,
  withoutClustering,
  withoutGeoFencing,
  geoFencingLocation,
}: Object) => {
  const dispatch = useDispatch();

  const [mapZoom, setMapZoom] = useState(4);

  const currentLocation = useSelector(makeSelectCurrentLocation());

  const handleSetZoom = useCallback((zoom: number) => {
    setMapZoom(zoom);
  }, []);

  const closePopup = useCallback(() => {
    dispatch(getGeoFencingLocationSuccess(null));
    dispatch(getFleetTrailerListSuccess(null));

    dispatch(closeModal());
  }, []);

  const openViewLocationPopup = useCallback(() => {
    const component = (
      <ViewLocationPopup
        fromTrailer={true}
        closeModal={closePopup}
        currentLocation={currentLocation}
      />
    );

    const modal = {
      p: '0px',
      component,
      options: {
        width: 'auto',
        height: 'auto',
        movable: false,
        withCloseIcon: true,
        title: G.getWindowLocale('titles:view-location', 'View Location'),
      },
    };

    dispatch(openModal(modal));
  }, [closePopup, currentLocation]);

  const viewLocation = useCallback((guid: string) => {
    dispatch(getGeoFencingLocationRequest({ guid, mode: VIEW_MODE }));
  }, []);

  useEffect(() => {
    if (G.isNotNilAndNotEmpty(currentLocation)) {
      openViewLocationPopup();
    }
  }, [currentLocation, openViewLocationPopup]);

  const markersContainerForGM = (
    <FleetMarkerWithInfo
      locations={locations}
      infoBorderColor={G.getTheme('map.infoBoxBorderColor')}
    />
  );

  return (
    loaded &&
    <Map
      width='100%'
      locations={locations}
      markerIcon={markerIcon}
      height='calc(100% - 62px)'
      handleSetZoom={handleSetZoom}
      withoutClustering={withoutClustering}
      childMapComponent={
        <GeoFencingLocation
          zoom={mapZoom}
          viewLocation={viewLocation}
          trailersActive={trailersActive}
          withoutGeoFencing={withoutGeoFencing}
          geoFencingLocation={geoFencingLocation}
        />
      }
      markersContainerForGM={markersContainerForGM}
      markerIconColor={G.getTheme('colors.dark.blue')}
    />
  );
};

export default FleetsMapComponent;
