// helpers/constants
import * as GC from '../../../constants';
import { notificationLevelOptions, getTrailerManageFromEnumOptions } from '../../../helpers/options';
// feature configs
import { MultiselectFieldComponent } from '../ui';
//////////////////////////////////////////////////

const TRAILER_GENERAL = [
  {
    title: 'titles:general',
    fields: {
      [GC.TRAILER_MANAGE_TRAILERS_FROM]: {
        type: 'select',
        name: 'titles:manage-trailers-from',
        options: getTrailerManageFromEnumOptions(),
        nameForAttribute: GC.TRAILER_MANAGE_TRAILERS_FROM,
      },
      [GC.TRAILER_REQUIRED_DOCUMENT_TYPES]: {
        zIndex: 12,
        type: 'list',
        options: 'documentTypes',
        component: MultiselectFieldComponent,
        name: 'titles:required-document-types',
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.TRAILER_REQUIRED_DOCUMENT_TYPES,
      },
      [GC.TRAILER_REQUIRED_DOCUMENT_WARNING_LEVEL]: {
        type: 'select',
        options: notificationLevelOptions(),
        name: 'titles:required-documents-warning-level',
        nameForAttribute: GC.TRAILER_REQUIRED_DOCUMENT_WARNING_LEVEL,
      },
      [GC.TRAILER_SHOW_IN_BRANCH_GUIDS]: {
        type: 'list',
        options: 'branchList',
        name: 'titles:show-in-branches',
        component: MultiselectFieldComponent,
        shouldGetInheritedValueFromConfigOptions: true,
        nameForAttribute: GC.TRAILER_SHOW_IN_BRANCH_GUIDS,
      },
      [GC.TRAILER_DEACTIVATE_ON_SERVICE_ISSUE]: {
        type: 'switcher',
        name: 'titles:deactivate-on-service-issue',
        nameForAttribute: GC.TRAILER_DEACTIVATE_ON_SERVICE_ISSUE,
      },
      [GC.TRAILER_DEFAULT_OUT_OF_SERVICE_REASON]: {
        type: 'select',
        options: 'outOfServiceReasons',
        name: 'titles:default-out-of-service-reason',
        nameForAttribute: GC.TRAILER_DEFAULT_OUT_OF_SERVICE_REASON,
      },
    },
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:trailer-type',
    editPopupTitle: 'titles:edit',
    configName: GC.TRAILER_TRAILER_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:document-type',
    editPopupTitle: 'titles:edit',
    configName: GC.TRAILER_DOCUMENT_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:insurance-type',
    configName: GC.TRAILER_INSURANCE_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:out-of-service-reason',
    configName: GC.TRAILER_OUT_SERVICE_REASON,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:unavailability-reason',
    configName: GC.TRAILER_UNAVAILABILITY_REASON,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:door-type',
    editPopupTitle: 'titles:edit',
    configName: GC.TRAILER_DOOR_TYPE,
  },
  {
    fields: [],
    configType: 'dropdown',
    title: 'titles:body-material',
    editPopupTitle: 'titles:edit',
    configName: GC.TRAILER_BODY_MATERIAL,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:specification-class',
    configName: GC.TRAILER_SPECIFICATION_CLASS,
  },
  {
    fields: [],
    configType: 'dropdown',
    editPopupTitle: 'titles:edit',
    title: 'titles:refrigeration-manufacturer',
    configName: GC.TRAILER_REFTIGERATION_MANUFACTURER,
  },
];

const TRAILER_MAINTENANCE = [
  {
    title: 'titles:maintenance',
    fields: {
      [GC.TRAILER_SERVICE_ID_AUTOGENERATED]: {
        type: 'switcher',
        name: 'titles:auto-service-id',
        nameForAttribute: GC.TRAILER_SERVICE_ID_AUTOGENERATED,
      },
      [GC.TRAILER_SERVICE_ID_SEQUENCE]: {
        type: 'select',
        options: 'sequences',
        name: 'titles:service-id-sequence',
        nameForAttribute: GC.TRAILER_SERVICE_ID_SEQUENCE,
      },
      [GC.TRAILER_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE]: {
        type: 'select',
        options: 'documentTypes',
        name: 'titles:ai-upload-default-document-type',
        nameForAttribute: GC.TRAILER_MAINTENANCE_AI_UPLOAD_DEFAULT_DOCUMENT_TYPE,
      },
      [GC.TRAILER_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE]: {
        type: 'select',
        options: 'documentTypes',
        name: 'titles:documents-default-document-type',
        nameForAttribute: GC.TRAILER_MAINTENANCE_DOCUMENTS_DEFAULT_DOCUMENT_TYPE,
      },
    },
  },
];

const ConfigTrailerGroup = {
  TRAILER_GENERAL,
  TRAILER_MAINTENANCE,
};

export {
  ConfigTrailerGroup,
};
