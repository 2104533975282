import * as R from 'ramda';
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
// components
import { openLoader, closeLoader } from '../../components/loader/actions';
// features
import { makeSelectCurrentBranchGuid } from '../branch/selectors';
import { geoFencingLocationReport } from '../geo-fencing-location/settings/location-settings';
// helpers/constants
import * as G from '../../helpers';
import * as GC from '../../constants';
// sagas
import { visitPageSaga } from '../../sagas';
// utilities
import { sendRequest } from '../../utilities/http';
import endpointsMap from '../../utilities/endpoints';
// feature fleet-map
import { makeSelectFleets } from './selectors';
import {
  setFleetsToStore,
  setFleetsLoadedToStore,
  openMapPageFromFleetList,
  setGeoFencingLocationToStore,
} from './actions';
//////////////////////////////////////////////////

function* getFleetListSaga({ endpoint, fleetType }: Object) {
  try {
    const branchGuid = yield select(makeSelectCurrentBranchGuid());

    const options = {
      params: { [GC.FIELD_BRANCH_GUID]: branchGuid },
    };

    const res = yield call(sendRequest, 'get', endpoint, options);

    const { status, data } = res;

    if (G.isResponseSuccess(status)) {
      const currentFleets = yield select(makeSelectFleets());
      const currentFleetData = currentFleets[fleetType];

      if (G.notEquals(currentFleetData, data)) {
        yield put(setFleetsToStore({ fleetType, data }));
      }
    } else {
      yield call(G.handleFailResponse, res, 'getFleetListSaga fail');
    }
  } catch (error) {
    yield call(G.handleException, error, 'getFleetListSaga exception');
  }
}

function* geoFencingLocationListRequestSaga() {
  try {
    yield put(openLoader({ showDimmer: true }));

    const currentEnterprise = yield select(makeSelectCurrentBranchGuid());

    const reqBody = {
      offset: 0,
      limit: 1000,
      currentEnterprise,
      fields: G.getOrElse(geoFencingLocationReport, 'fields', []),
    };

    const reqData = G.setSearchCriteria({ reqBody });

    const res = yield call(sendRequest, 'post', endpointsMap.geoFencingLocationList, { data: reqData });

    const { data, status } = res;

    if (G.isResponseSuccess(status)) {
      yield put(setGeoFencingLocationToStore(data));
    } else {
      yield call(G.handleFailResponse, res, 'geoFencingLocationListRequestSaga fail');
    }

    yield put(closeLoader());
  } catch (error) {
    yield put(closeLoader());

    yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
    yield call(G.handleException, error, 'geoFencingLocationListRequestSaga exception');
  }
}

function* handleOpenMapPageFromFleetListSaga({ payload }: Object) {
  yield put(openLoader({ showDimmer: true }));

  const { fleetsLoaded } = yield select(makeSelectFleets());

  if (R.equals(payload, GC.FLEET_TRAILER_REPORT)) {
    yield put(setFleetsLoadedToStore({
      ...fleetsLoaded,
      trucks: true,
      drivers: true,
    }));

    yield call(getFleetListSaga, {
      fleetType: 'trailers',
      endpoint: endpointsMap.trailerWithLastLocationListFleetMap,
    });

    yield call(geoFencingLocationListRequestSaga);
  }

  if (R.equals(payload, GC.FLEET_TRUCK_REPORT)) {
    yield put(setFleetsLoadedToStore({
      ...fleetsLoaded,
      drivers: true,
      trailers: true,
    }));

    yield call(getFleetListSaga, {
      fleetType: 'trucks',
      endpoint: endpointsMap.truckWithLastLocationList,
    });
  }

  yield put(closeLoader());
}

function* handleVisitAnalyticMapPageSaga({ payload }: Object) {
  yield call(visitPageSaga, payload, GC.CHECK_VISIT_ANALYTIC_MAPS_PAGE);

  yield put(openLoader({ showDimmer: true }));

  yield all([
    call(getFleetListSaga, {
      fleetType: 'drivers',
      endpoint: endpointsMap.driverWithLastLocationList,
    }),
    call(getFleetListSaga, {
      fleetType: 'trucks',
      endpoint: endpointsMap.truckWithLastLocationList,
    }),
    call(getFleetListSaga, {
      fleetType: 'trailers',
      endpoint: endpointsMap.trailerWithLastLocationListFleetMap,
    }),
  ]);

  yield call(geoFencingLocationListRequestSaga);

  yield put(closeLoader());
}

function* fleetMapWatcherSaga() {
  yield takeLatest(GC.VISIT_ANALYTIC_MAPS_PAGE, handleVisitAnalyticMapPageSaga);
  yield takeLatest(openMapPageFromFleetList, handleOpenMapPageFromFleetListSaga);
}

export default fleetMapWatcherSaga;
