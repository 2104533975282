import React from 'react';
import * as Yup from 'yup';
// components
import { SelectFleetEntity } from '../../../../components/select-fleet-entity';
// helpers/constants
import * as G from '../../../../helpers';
import * as GC from '../../../../constants';
import { priorityTypeOptions, fleetServiceIssueStatusOptions } from '../../../../helpers/options';
//////////////////////////////////////////////////

const validationSchema = ({ isTruck }: Object) => Yup.object().shape({
  [GC.FIELD_SUMMARY]: G.yupStringRequired,
  [GC.FIELD_ISSUE_ID]: G.yupStringRequired,
  [G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID)]: G.yupStringRequired,
});

const defaultValues = {
  [GC.FIELD_TYPE]: null,
  [GC.FIELD_SUMMARY]: '',
  [GC.FIELD_ISSUE_ID]: '',
  [GC.FIELD_PRIORITY]: '',
  [GC.FIELD_TRUCK_GUID]: '',
  [GC.FIELD_DESCRIPTION]: '',
  [GC.FIELD_TRAILER_GUID]: '',
  [GC.FIELD_ASSIGNEE_GUID]: '',
};

const inputWrapperStyles = {
  mb: 25,
  width: 215,
};

const getFieldSettings = ({
  isEdit,
  isTruck,
  selectedFleetEntity,
  setSelectedFleetEntity,
  openedFromFleetProfile,
}: Object) => [
  {
    type: 'text',
    isRequired: true,
    fieldName: GC.FIELD_SUMMARY,
    label: ['titles:summary', 'Summary'],
    inputWrapperStyles: {
      ...inputWrapperStyles,
      width: '100%',
    },
  },
  {
    type: 'text',
    isRequired: true,
    inputWrapperStyles,
    fieldName: GC.FIELD_ISSUE_ID,
    label: ['titles:issue-id', 'Issue Id'],
  },
  {
    isRequired: true,
    inputWrapperStyles,
    type: 'customComponent',
    fieldName: G.ifElse(isTruck, GC.FIELD_TRUCK_GUID, GC.FIELD_TRAILER_GUID),
    label: G.ifElse(isTruck, ['titles:truck', 'Truck'], ['titles:trailer', 'Trailer']),
    Component: ({ name, value, hasError, setFieldValue }: Object) => (
      <SelectFleetEntity
        value={value}
        fieldName={name}
        isTruck={isTruck}
        isClearable={true}
        hasError={hasError}
        useMenuPortalTarget={true}
        handleSelect={setFieldValue}
        selectedFleetEntity={selectedFleetEntity}
        setSelectedFleetEntity={setSelectedFleetEntity}
        isDisabled={G.isAnyTrue(isEdit, openedFromFleetProfile)}
      />
    ),
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    fieldName: GC.FIELD_TYPE,
    options: 'serviceIssueTypeOptions',
    label: ['titles:issue-type', 'Issue Type'],
  },
  {
    inputWrapperStyles,
    type: 'reactSelect',
    options: 'assigneeOptions',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_ASSIGNEE_GUID,
    label: ['titles:assigned-to', 'Assigned To'],
  },
  {
    type: 'select',
    inputWrapperStyles,
    fieldName: GC.FIELD_PRIORITY,
    options: priorityTypeOptions,
    label: ['titles:priority', 'Priority'],
  },
  {
    type: 'select',
    fieldName: GC.FIELD_STATUS,
    label: ['titles:status', 'Status'],
    options: fleetServiceIssueStatusOptions,
    inputWrapperStyles: {
      ...inputWrapperStyles,
      display: G.ifElse(isEdit, 'block', 'none'),
    },
  },
  {
    type: 'textarea',
    fieldName: GC.FIELD_DESCRIPTION,
    label: ['titles:description', 'Description'],
    inputWrapperStyles: { width: '100%', mb: G.ifElse(isEdit, 0, 25) },
  },
  {
    type: 'files',
    fieldName: GC.FIELD_FILES,
    label: ['titles:upload-files', 'Upload Files'],
    inputWrapperStyles: {
      mb: 0,
      width: '100%',
      display: G.ifElse(isEdit, 'none', 'block'),
    },
  },
];

const serviceVendorFieldSettings = [
  {
    type: 'reactSelect',
    useMenuPortalTarget: true,
    fieldName: GC.FIELD_SERVICE_VENDOR_GUID,
    options: 'serviceVendorListAvailableOptions',
    inputWrapperStyles: { mb: 25, width: '100%' },
    label: ['titles:service-vendor', 'Service Vendor'],
  },
];

export {
  defaultValues,
  getFieldSettings,
  validationSchema,
  serviceVendorFieldSettings,
};
