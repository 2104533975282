import React from 'react';
import * as R from 'ramda';
// components
import { ActionBox } from '../../../components/action-box';
// helpers/constants
import * as G from '../../../helpers';
import * as GC from '../../../constants';
// ui
import { Box, Text } from '../../../ui';
//////////////////////////////////////////////////

const renderItemInfo = (
  index: number,
  fleet: Object,
  fleetType: string,
  lnl: Object,
  setExpandedContainerOptions: Function,
) => {
  const { lastModifiedDate } = lnl;

  const {
    email,
    guid,
    unitId,
    lastName,
    firstName,
    phoneNumber,
    enterpriseGuid,
  } = fleet;

  if (R.equals(fleetType, 'drivers')) {
    return (
      <div key={index}>
        <Text fontWeight='bold'>{`${firstName} ${lastName}`}</Text>
        <div>{G.concatLocationFields(lnl)}</div>
        <div>{lastModifiedDate}</div>
        <div>{phoneNumber}</div>
        <div>{email}</div>
      </div>
    );
  }

  if (R.equals(fleetType, 'trailers')) {
    const branchName = G.getBranchNameFromWindowByGuid(enterpriseGuid);

    return (
      <div key={index}>
        <ActionBox
          text={unitId}
          fontWeight='bold'
          action={() => setExpandedContainerOptions({
            opened: true,
            visitPageGuid: guid,
            componentType: GC.PAGE_FLEET_TRAILER_PROFILE_V2,
          })}
        />
        { G.isNotNilAndNotEmpty(branchName) && <Text fontWeight='bold'>{branchName}</Text> }
        <div>{G.concatLocationFields(lnl)}</div>
        <div>{lastModifiedDate}</div>
      </div>
    );
  }

  return (
    <div key={index}>
      <Text fontWeight='bold'>{unitId}</Text>
      <div>{G.concatLocationFields(lnl)}</div>
      <div>{lastModifiedDate}</div>
    </div>
  );
};

const FleetsContent = ({ lnl, fleets, fleetType, setExpandedContainerOptions }: Object) => (
  <Box
    height='100%'
    overflow='auto'
    maxHeight={400}
  >
    {
      fleets.map((fleet: Object, index: number) => (
        renderItemInfo(index, fleet, fleetType, lnl, setExpandedContainerOptions)
      ))
    }
  </Box>
);

const renderMultiContent = (fleetType: string, fleets: Array, setExpandedContainerOptions: Function) =>
  fleets.map((item: Object, index: number) => {
    const fleet = R.prop('fleetObj', item);
    const lnl = R.prop('lnl', item);

    return renderItemInfo(index, fleet, fleetType, lnl, setExpandedContainerOptions);
  },
);

const MultiFleetContent = ({ drivers, trucks, trailers, setExpandedContainerOptions }: Object) => (
  <Box
    height='100%'
    overflow='auto'
    maxHeight={400}
  >
    {G.isNotNilAndNotEmpty(drivers) && renderMultiContent('drivers', drivers, setExpandedContainerOptions)}
    {G.isNotNilAndNotEmpty(trucks) && renderMultiContent('trucks', trucks, setExpandedContainerOptions)}
    {G.isNotNilAndNotEmpty(trailers) && renderMultiContent('trailers', trailers, setExpandedContainerOptions)}
  </Box>
);

export { FleetsContent, MultiFleetContent };
