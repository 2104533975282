import * as R from 'ramda';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// helpers/constants
import * as GC from '../../../constants';
// ui
import { Box, Grid } from '../../../ui';
// feature geo-fencing-location
import * as H from '../helpers';
import LocationMap from './location-map';
import TrailerList from './trailer-list';
import { makeSelectListLoading, makeSelectTrailersByLocation } from '../selectors';
//////////////////////////////////////////////////

const ViewLocationPopup = (props: Object) => {
  const { closeModal, fromTrailer, currentLocation} = props;

  const loading = useSelector(makeSelectListLoading());
  const trailerList = useSelector(makeSelectTrailersByLocation());

  const position = R.pick(['latitude', 'longitude'], R.or(currentLocation, {}));

  const radius = R.prop(GC.FIELD_FLEET_LOCATION_RADIUS, currentLocation);

  useEffect(() => () => closeModal(), []);

  return (
    <Box
      width={1020}
      maxHeight='85vh'
      overflow='hidden'
      p='20px 15px 15px 15px'
    >
      <Grid gap={15} gridTemplateColumns='350px 1fr'>
        <TrailerList
          loading={loading}
          trailerList={trailerList}
          fromTrailer={fromTrailer}
          currentLocation={currentLocation}
        />
        <LocationMap mode={H.VIEW_MODE} radius={radius} position={position} trailerList={trailerList} />
      </Grid>
    </Box>
  );
};

export default ViewLocationPopup;
