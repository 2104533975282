import { createSelector } from 'reselect';
// feature fleet-map
import {
  getFilteredByIdleTimeTrailers,
  getFilteredByEnterpriseFleetsEntity,
} from './helpers';
//////////////////////////////////////////////////

const selectFleetMapStore = (state: Object) => state.fleetMap;

const makeSelectFleets = () => createSelector(
  selectFleetMapStore,
  ({ fleets, enterpriseFilter, idleTimeFilter }: Object) => {
    const getFilteredEntities = getFilteredByEnterpriseFleetsEntity(enterpriseFilter, fleets);

    return {
      ...fleets,
      trucks: getFilteredEntities('trucks'),
      drivers: getFilteredEntities('drivers'),
      trailers: getFilteredByIdleTimeTrailers(idleTimeFilter, getFilteredEntities('trailers')),
    };
  },
);

const makeSelectGeoFencingLocation = () => createSelector(
  selectFleetMapStore,
  ({ geoFencingLocation }: Object) => geoFencingLocation,
);

export {
  makeSelectFleets,
  makeSelectGeoFencingLocation,
};
